import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    banners: [],
    isLoading: true,
    count: 0
};

const settingsSlice = createSlice({
    name: "event",
    initialState: initialState,
    reducers: {
        getBanners: (state, action) => {
            // const categories = action.payload;
            state.banners = action.payload;
            // state.count = categories.count;
        },
        loadingHandler: (state, action) => {
            state.isLoading = action.payload;
        },
        modalHandler: (state, action) => {
            state.isOpenModal = action.payload;
        },
    },
});

export const settingsActions = settingsSlice.actions;

export default settingsSlice;