import { Bar } from 'react-chartjs-2';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const MonthlyReportChart = ({ monthlyReport, reportName }) => {
    const chartData = transformDataForChart(monthlyReport);
    
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: reportName,
            },
        },
    };

    return (
        <div className="mt-4" style={{ height: "50vh", backgroundColor: "white", padding: 20 }}>
            <Bar
                data={chartData}
                options={options}
            />
        </div>
    );
};
const transformDataForChart = (monthlyReport) => {
    const labels = monthlyReport.map(entry => `${entry._id.year}-${entry._id.month}`);
    const ordersData = monthlyReport.some(entry => entry.totalOrders);
    const amountData = monthlyReport.some(entry => entry.totalAmount);
    const quantityData = monthlyReport.some(entry => entry.totalQuantity);
    const originalPriceData = monthlyReport.some(entry => entry.totalOriginalPrice);
    const profitData = monthlyReport.some(entry => entry.totalProfit);

    console.log(labels)
    console.log(ordersData)
    console.log(amountData)
    console.log(quantityData)

    const data = {
        datasets: []
    };

    if(labels.length > 0) {
        data.labels = labels;
    }

    if(ordersData) {
        data.datasets.push({
            label: 'Total Orders',
            data: monthlyReport.map(entry => entry.totalOrders),
            backgroundColor: 'rgba(75, 192, 192, 0.2)', // Adjust color as needed
            borderColor: 'rgba(75, 192, 192, 1)', // Adjust color as needed
            borderWidth: 1,
        });
    }

    if(quantityData) {
        data.datasets.push({
            label: 'Total Quantity',
            data: monthlyReport.map(entry => entry.totalQuantity),
            backgroundColor: 'rgba(255, 99, 132, 0.2)', // Adjust color as needed
            borderColor: 'rgba(255, 99, 132, 1)', // Adjust color as needed
            borderWidth: 1,
        });
    }

    if(profitData) {
        data.datasets.push({
            label: 'Total Profit',
            data: monthlyReport.map(entry => entry.totalProfit),
            backgroundColor: 'rgba(221, 160, 221, 0.7)',
            borderColor: 'rgba(128, 0, 128, 1)',
            borderWidth: 1,
        });
    }

    if(originalPriceData) {
        data.datasets.push({
            label: 'Total Cost',
            data: monthlyReport.map(entry => entry.totalOriginalPrice),
            backgroundColor: 'rgba(255, 255, 153, 0.7)',
            borderColor: 'rgba(255, 255, 0, 1)',
            borderWidth: 1,
        });
    }

    if(amountData) {
        data.datasets.push({
            label: 'Total Amount',
            data: monthlyReport.map(entry => entry.totalAmount),
            backgroundColor: 'rgba(75, 192, 100, 0.2)',
            borderColor: 'rgba(75, 192, 100, 1)',
            borderWidth: 1,
        });
    }

    return data;

};

export default MonthlyReportChart;