import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLogin: false,
    access: [],
};

const authSlice = createSlice({
    name: "event",
    initialState: initialState,
    reducers: {
        loginData: (state, action) => {
            state.access = action.payload.access;
        },
        isLogin: (state, action) => {
            state.isLogin = true;
        },
        isLogout: (state, action) => {
            localStorage.removeItem("isLogin");
            localStorage.removeItem("token");
            state.isLogin = false;
        },
    },
});

export const authAction = authSlice.actions;

export default authSlice;