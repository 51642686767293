export const resizeImage = (originalImage, targetWidth, targetHeight) => {
    return new Promise((resolve, reject) => {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');

        var img = new Image();
        img.src = URL.createObjectURL(originalImage);

        img.onload = function () {
            // Set the canvas dimensions to the target width and height
            canvas.width = targetWidth;
            canvas.height = targetHeight;

            // Draw the image onto the canvas with the new dimensions
            ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

            // Get the resized image as a Blob
            canvas.toBlob(
                (blob) => {
                    resolve(blob);
                },
                'image/jpeg', // You can change the format if needed
                0.9 // You can adjust the quality if needed
            );
        };

        img.onerror = function (error) {
            reject(error);
        };
    });
}


// export function resizeImage(originalImage, newWidth, newHeight) {
//     var canvas = document.createElement('canvas');
//     var ctx = canvas.getContext('2d');

//     var img = new Image();
//     img.src = originalImage;

//     img.onload = function () {
//         var aspectRatio = img.width / img.height;

//         // Check if both newWidth and newHeight are provided
//         if (newWidth !== undefined && newHeight !== undefined) {
//             // Use the provided width and height
//             canvas.width = newWidth;
//             canvas.height = newHeight;
//         } else if (newWidth !== undefined) {
//             // Calculate height based on width and aspect ratio
//             canvas.width = newWidth;
//             canvas.height = newWidth / aspectRatio;
//         } else if (newHeight !== undefined) {
//             // Calculate width based on height and aspect ratio
//             canvas.width = newHeight * aspectRatio;
//             canvas.height = newHeight;
//         } else {
//             // If neither width nor height is provided, use the original dimensions
//             canvas.width = img.width;
//             canvas.height = img.height;
//         }

//         ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

//         var resizedImage = canvas.toDataURL('image/jpeg');
//         console.log(resizedImage);
//         return resizedImage;
//     };
// }

// // Example usage:
// var originalImage = "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/..."; // Replace with your base64-encoded image
// var dynamicWidth = 300; // Set to your desired dynamic width
// var dynamicHeight = 200; // Set to your desired dynamic height

// resizeImage(originalImage, dynamicWidth, dynamicHeight);


// import Compress from "compress.js";
// import Resizer from "react-image-file-resizer";
// const compress = new Compress();

// const resizeFile = async (file, width, height) =>
//     new Promise((resolve) => {
//         Resizer.imageFileResizer(
//             file, // Is the file of the image which will resized.
//             width, // Is the maxWidth of the resized new image.
//             height, // Is the maxHeight of the resized new image.
//             "JPEG", // Is the compressFormat of the resized new image.
//             100, // Is the quality of the resized new image.
//             0, // Is the degree of clockwise rotation to apply to uploaded image.
//             (uri) => {
//                 resolve(uri);
//             }, // Is the callBack function of the resized new image URI.
//             "", // Is the output type of the resized new image.
//             width, // Is the minWidth of the resized new image.
//             height // Is the minHeight of the resized new image.
//         );
//     });



// export const resizeImage = async (file, width, height) => {
//     const newFile = await resizeFile(file, width, height);
//     console.log(newFile.split(',')[1]);
//     // const resizedImage = await compress.compress([file], {
//     //     size: 2, // the max size in MB, defaults to 2MB
//     //     quality: 1, // the quality of the image, max is 1,
//     //     maxWidth: undefined, // the max width of the output image, defaults to 1920px
//     //     maxHeight: undefined, // the max height of the output image, defaults to 1920px
//     //     resize: true // defaults to true, set false if you do not want to resize the image width and height
//     // });
//     // const img = resizedImage[0];
//     // console.log(img);
//     const base64str = newFile.split(',')[1]
//     // const imgExt = img.ext
//     const resizedFiile = Compress.convertBase64ToFile(base64str, 'image/jpeg')
//     return resizedFiile;
// };