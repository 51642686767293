import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Select, { StylesConfig } from 'react-select';
import makeAnimated from 'react-select/animated';
import chroma from 'chroma-js';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { Circles } from 'react-loader-spinner';

import { deleteFile, uploadFileToFirebase } from '../../../util/firebaseService';
import * as customerService from "../../../services/customerService";
import style from "./addOrEditCustomer.module.css";
import { Check, Delete, Sick } from '@mui/icons-material';
import { Divider } from '@mui/material';
import { customerActions } from '../../../store/customerSlice';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function AddOrEditStaff({ title, modalOpen, action, modalHandler, customerId }) {
    const dispatch = useDispatch();
    const customers = useSelector((state) => state.customer.customerList);
    console.log(customers);

    const [name, setName] = useState("");
    const [telephone, setTelephone] = useState("");

    // const [email, setEmail] = useState("");
    // const [isSystemUser, setIsSystemUser] = useState(false);
    // const [role, setRole] = useState("");
    const [address, setAddress] = useState({
        street: "",
        city: "",
        state: "",
        postalCode: "",
        country: "",
    });
    const [addedAddress, setAddedAddress] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState([]);

    // const [uploadImages, setUploadImages] = useState(undefined);
    // const [file, setFile] = useState("");
    // const [isUploadingImage, setIsUploadingImage] = useState(false);


    useEffect(() => {
        if (customerId) {
            const customer = customers.find(el => el._id === customerId);
            // console.log(customer);
            // setProduct(order.product._id);
            // setPrice(order.amount);
            // setColor(order.color);
            // setSize(order.size);
            // setQuantity(order.quantity);
            setName(customer.name);
            setTelephone(customer.telephone);
            setAddedAddress(customer.address)
        }
    }, [customerId]);


    const handleClose = () => {
        dispatch(customerActions.modalHandler(false));
    };

    const createCustomer = () => {
        const customerJson = {
            name,
            telephone,
            address: addedAddress,
        }
        console.log(customerJson);
        dispatch(customerService.createCustomer(customerJson));
    }

    const updateCustomer = () => {
        const customerJson = {
            name,
            telephone,
            selectedAddress,
            address: addedAddress,
        }
        console.log(customerJson, customerId);
        dispatch(customerService.updateCustomer(customerJson, customerId));
    }

    const addAddress = () => {
        setAddedAddress([...addedAddress, address]);
        setAddress({
            street: "",
            city: "",
            state: "",
            postalCode: "",
            country: ""
        })
    }

    const selectedAddressHandler = (id) => {
        if (selectedAddress.includes(id)) {
            setSelectedAddress(selectedAddress.filter(el => el !== id));
        } else {
            setSelectedAddress([...selectedAddress, id]);
        }
    }

    console.log(addedAddress);

    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={modalOpen}
                maxWidth="lg"
                fullWidth={true}
            >
                <DialogTitle sx={{ m: 0, p: 2, backgroundColor: "#1976d2", color: "white" }} id="customized-dialog-title">
                    {title}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: "white",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <div className='row mb-2'>
                        <div className="col-8">
                            <label for="exampleFormControlInput1" className={style.input_label}>Name</label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} disabled={action === 'view'} />
                        </div>
                        <div className="col-4">
                            <label for="exampleFormControlInput1" className={style.input_label}>Telephone</label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Telephone" value={telephone} onChange={(e) => setTelephone(e.target.value)} disabled={action === 'view'} />
                        </div>
                    </div>
                    {/* <hr variant="inset" /> */}
                    <p style={{ margin: "10px 0px", fontWeight: 500 }}>Manage Address</p>
                    <div className='row mt-202'>
                        <div className="col-4">
                            <label for="exampleFormControlInput1" className={style.input_label}>Street</label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Street" value={address.street} onChange={(e) => setAddress({ ...address, street: e.target.value })} disabled={action === 'view'} />
                        </div>
                        <div className="col-4">
                            <label for="exampleFormControlInput1" className={style.input_label}>City</label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="City" value={address.city} onChange={(e) => setAddress({ ...address, city: e.target.value })} disabled={action === 'view'} />
                        </div>
                        <div className="col-4">
                            <label for="exampleFormControlInput1" className={style.input_label}>State</label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="State" value={address.state} onChange={(e) => setAddress({ ...address, state: e.target.value })} disabled={action === 'view'} />
                        </div>
                    </div>

                    <div className='row mt-2'>
                        <div className="col-4">
                            <label for="exampleFormControlInput1" className={style.input_label}>Postal Code</label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Postal Code" value={address.postalCode} onChange={(e) => setAddress({ ...address, postalCode: e.target.value })} disabled={action === 'view'} />
                        </div>
                        <div className="col-4">
                            <label for="exampleFormControlInput1" className={style.input_label}>Country</label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Country" value={address.country} onChange={(e) => setAddress({ ...address, country: e.target.value })} disabled={action === 'view'} />
                        </div>
                        <div className="col-4">
                            <label for="exampleFormControlInput1" className={style.input_label}></label>
                            <div>
                                <button class="btn"><i style={{ color: "blue" }} class="fa fa-plus" onClick={addAddress}></i></button>
                            </div>
                        </div>
                    </div>

                    <div className="mt-2 d-flex flex-wrap">
                        {addedAddress.map(address => {
                            return (
                                <div
                                    className={style.customer_address} key={address._id} onClick={() => selectedAddressHandler(address._id)}>
                                    {!selectedAddress.includes(address._id) && address._id && <div style={{ textAlign: "end" }}><Delete fontSize="small" sx={{ color: "red", cursor: "pointer" }} /></div>}
                                    {selectedAddress.includes(address._id) && <div style={{ textAlign: "end" }}><Check fontSize="small" sx={{ color: "red", cursor: "pointer" }} /></div>}
                                    <div>{address.street}</div>
                                    <div>{address.city}</div>
                                    <div>{address.state}</div>
                                    <div>{address.postalCode}</div>
                                    <div>{address.country}</div>
                                </div>
                            );
                        })}
                    </div>

                </DialogContent>
                {action !== 'view' && <DialogActions>
                    <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
                    {action === "create" && <button className="btn btn-primary" onClick={createCustomer}>
                        Create
                    </button>}
                    {action === "edit" && <button className="btn btn-primary" onClick={updateCustomer}>
                        Update
                    </button>}
                </DialogActions>}
            </BootstrapDialog>
        </>
    );
}
