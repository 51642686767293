import { useState, useEffect } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { warningNotify } from '../../config/notify';

const DeleteConfirmationModal = ({ isDeleteModalOpen, deleteModalHandler }) => {
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState("");

  const handleClose = (action) => {
    if (action === "confirm" && !reason) {
      return warningNotify("Please provide a reason.");
    }
    deleteModalHandler(action, reason);
  };

  useEffect(() => {
    setOpen(isDeleteModalOpen);
  }, [isDeleteModalOpen])

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"sm"}
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title" sx={{ m: 0, p: 2, backgroundColor: "#1976d2", color: "white" }}>
          Are you sure you want to delete?
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: "white",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Please provide a reason."
            type="text"
            fullWidth
            variant="standard"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={() => handleClose("cancel")}>Close</button>
          <button type="button" class="btn btn-primary" onClick={() => handleClose("confirm")}>Confirm</button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteConfirmationModal;