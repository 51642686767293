// export const API_HOST = "https://shop-b6ad.onrender.com";
// export const API_HOST = "https://js-api-22f9f315a447.herokuapp.com/";
export const API_HOST = "https://js-api-production.up.railway.app";
// export const API_HOST = "http://localhost:4000/js-api";
// export const API_HOST = "https://js-api-dz4i.onrender.com";
// export const API_HOST = "https://d-96-demo-425d4fdd700a.herokuapp.com";

export const steps = ['Order Placed', 'Processing', 'Shipped', 'Out for Delivery', 'Delivered'];
export const stepColors = ['#FF6B6B', '#45AAB8', '#FFD166', '#06D6A0', '#5368A2'];

export const symbol = "$";

export const colorOptions = [
    { value: "blue", label: "Blue", color: "blue", isFixed: true },
    { value: "red", label: "Red", color: "red", isFixed: true },
    { value: "white", label: "White", color: "white", isFixed: true },
    { value: "black", label: "Black", color: "black", isFixed: true },
    { value: "green", label: "Green", color: "green", isFixed: true },
    { value: "orange", label: "Orange", color: "orange", isFixed: true },
]

export const sizesOptions = [
    { value: "XXS", label: "XXS", isFixed: true, order: 1 },
    { value: "XS", label: "XS", isFixed: true, order: 2 },
    { value: "S", label: "S", isFixed: true, order: 3 },
    { value: "M", label: "M", isFixed: true, order: 4 },
    { value: "L", label: "L", isFixed: true, order: 5 },
    { value: "XL", label: "XL", isFixed: true, order: 6 },
    { value: "2XL", label: "2XL", isFixed: true, order: 7 },
    { value: "3XL", label: "3XL", isFixed: true, order: 8 },
]