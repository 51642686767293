import axios from 'axios';
import { productActions } from "../store/productSlice"
import { API_HOST } from '../config/config';
import { successNotify, errorNotify } from '../config/notify';
import { removeImage } from '../util/removeImages';
import axiosInstance from '../config/axiosInstance';

export const getProducts = (params) => (dispatch) => {
    axiosInstance({
        method: 'get',
        url: API_HOST + '/products',
        params: params,
    }).then(res => {
        dispatch(productActions.getProducts(res.data.data));
        dispatch(productActions.loadingHandler(false));
    }).catch(err => {
        errorNotify(err.response?.data?.message || err.message);
        dispatch(productActions.loadingHandler(false));
    });
}

export const createProduct = (productJson, removeImageArray) => (dispatch) => {
    axiosInstance({
        method: 'post',
        url: API_HOST + '/products',
        data: productJson
    }).then(res => {
        successNotify("Successfully created product.");
        dispatch(productActions.modalHandler(false));
        dispatch(productActions.loadingHandler(true));
        dispatch(productActions.refreshItems());
        if(removeImageArray?.length > 0) {
            removeImage(removeImageArray);
        }
    }).catch(err => {
        console.log(err.response);
        errorNotify(err.response?.data?.message || err.message);
        dispatch(productActions.loadingHandler(false));
    });

}

export const updateProduct = (productJson, id, removeImageArray) => (dispatch) => {
    axiosInstance({
        method: 'put',
        url: API_HOST + `/products/${id}`,
        data: productJson
    }).then(res => {
        successNotify("Successfully updated product.");
        dispatch(productActions.modalHandler(false));
        dispatch(productActions.loadingHandler(true));
        dispatch(productActions.refreshItems());
        if(removeImageArray?.length > 0) {
            removeImage(removeImageArray);
        }
    }).catch(err => {
        console.log(err.response);
        errorNotify(err.response?.data?.message || err.message);
        dispatch(productActions.loadingHandler(false));
    });
}

export const deleteProduct = (id, reason, previousImage) => (dispatch) => {
    console.log(id)
    axiosInstance({
        method: 'delete',
        url: API_HOST + `/products/${id}`,
        data: {
            reason,
        }
    }).then(() => {
        dispatch(productActions.loadingHandler(true));
        successNotify("Successfully deleted product.");
        dispatch(productActions.refreshItems());
        if(previousImage?.length > 0) {
            removeImage(previousImage);
        }
    }).catch(err => {
        console.log(err.response);
        dispatch(productActions.loadingHandler(false));
        errorNotify(err.response?.data?.message || err.message);
    });
};

export const updateVisible = (id, isVisible) => (dispatch) => {
    axiosInstance({
        method: 'put',
        url: API_HOST + `/products/${id}/update-visible`,
        data: {
            isVisible
        }
    }).then(() => {
        if(isVisible) {
            successNotify("Successfully updated to be visible to the customer.");
        } else {
            successNotify("Successfully updated to be invisible to the customer.");
        }
        dispatch(productActions.refreshItems());
    }).catch(err => {
        console.log(err.response);
        errorNotify(err.response?.data?.message || err.message);
        dispatch(productActions.loadingHandler(false));
    });
};