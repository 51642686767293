import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    count: 0,
    products: [],
    isLoading: true,
    isOpenModal: false,
    isRefreshItems: false
};

const productSlice = createSlice({
    name: "event",
    initialState: initialState,
    reducers: {
        getProducts: (state, action) => {
            const products = action.payload;
            // console.log(products)
            state.products = products.products;
            state.count = products.count;
            state.isRefreshItems = false;
        },
        loadingHandler: (state, action) => {
            state.isLoading = action.payload;
        },
        modalHandler: (state, action) => {
            state.isOpenModal = action.payload;
        },
        refreshItems: (state, action) => {
            state.isRefreshItems = true;
        },
    },
});

export const productActions = productSlice.actions;

export default productSlice;