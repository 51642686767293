import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Select, { StylesConfig } from 'react-select';
import makeAnimated from 'react-select/animated';
import chroma from 'chroma-js';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { Circles } from 'react-loader-spinner';

import { deleteFile, uploadFileToFirebase } from '../../../util/firebaseService';
import * as staffService from "../../../services/staffServices";
import style from "./addOrEditStaff.module.css";
import { Sick } from '@mui/icons-material';
import { staffActions } from '../../../store/staffSlice';
import { refreshRoles } from '../../refresh';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function AddOrEditStaff({ title, modalOpen, action, modalHandler, staffId }) {
    const dispatch = useDispatch();
    const staffList = useSelector((state) => state.staff.staff);
    const systemRoles = useSelector((state) => state.systemRoles.systemRoles);

    console.log(staffId)

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [telephone, setTelephone] = useState("");
    const [isSystemUser, setIsSystemUser] = useState(false);
    const [role, setRole] = useState("");
    const [address, setAddress] = useState()

    const [uploadImages, setUploadImages] = useState(undefined);
    const [file, setFile] = useState("");
    const [isUploadingImage, setIsUploadingImage] = useState(false);

    useEffect(() => {
        refreshRoles();
      }, []);

    useEffect(() => {
        if (staffId) {
            console.log(staffList);
            const staff = staffList.find(el => el._id === staffId);
            setName(staff.name);
            setTelephone(staff.telephone);
            setEmail(staff.email);
            setAddress(staff.address);
            setIsSystemUser(staff.isSystemUser);
            setRole(staff.role);
        }
    }, [staffId]);


    const handleClose = () => {
        dispatch(staffActions.modalHandler(false));
    };

    const createStaff = () => {
        const staffJson = {
            name,
            email,
            telephone,
            address,
            // image: uploadImages,
            isSystemUser
        }
        if (isSystemUser) {
            staffJson.role = role;
        }
        console.log(staffJson);
        dispatch(staffService.createStaff(staffJson));
    }

    const updateStaff = () => {
        const staffJson = {
            name,
            email,
            telephone,
            address,
            // image: uploadImages,
            isSystemUser
        }
        if (isSystemUser) {
            staffJson.role = role;
        }
        console.log(staffJson, staffId);
        dispatch(staffService.updateStaff(staffJson, staffId));
    }

    const imageUpload = async (event) => {
        const selectedFile = event.target.files[0];
        console.log(event.target.files[0]);
        setIsUploadingImage(true);
        setFile({ file: selectedFile });
        uploadFileToFirebase(`staff/${selectedFile.name}-${new Date()}`, selectedFile)
            .then(downloadUrl => {
                setUploadImages(downloadUrl);
                setIsUploadingImage(false);
            })
            .catch(error => {
                console.error("Error uploading file:", error);
            });
    };

    const removeImage = (url) => {
        deleteFile(url)
            .then(() => {
                // const filterImages = uploadImages.filter(image => image != url);
                setUploadImages(undefined);
            })
            .catch(error => {
                console.error("Error deleting file:", error);
            });
    }

    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={modalOpen}
                maxWidth="lg"
                fullWidth={true}
            >
                <DialogTitle sx={{ m: 0, p: 2, backgroundColor: "#1976d2", color: "white" }} id="customized-dialog-title">
                    {title}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: "white",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <div className='row'>
                        <div className="col-8">
                            <label for="exampleFormControlInput1" className={style.input_label}>Name</label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} disabled={action === 'view'} />
                        </div>
                        <div className="col-4">
                            <label for="exampleFormControlInput1" className={style.input_label}>Telephone</label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Telephone" value={telephone} onChange={(e) => setTelephone(e.target.value)} disabled={action === 'view'} />
                        </div>

                    </div>
                    <div className='row mt-2'>
                        <div className="col-8">
                            <label for="exampleFormControlInput1" className={style.input_label}>Email</label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} disabled={action === 'view'} />
                        </div>
                        {/* <div className="col-4">
                            <label for="exampleFormControlInput1" className={style.input_label}>Image</label>
                            <div className='d-flex align-items-center'>
                                <input type="file" className="form-control" id="exampleFormControlInput1" placeholder="Image" accept="image/*" onChange={imageUpload} disabled={action === 'view'} />
                                <Circles
                                    height="30"
                                    color="#4fa94d"
                                    ariaLabel="circles-loading"
                                    visible={isUploadingImage}
                                />
                            </div>
                        </div> */}
                    </div>
                    <div className='row mt-2'>
                        <div className='col-12'>
                            <label for="exampleFormControlInput1" className={style.input_label}>Address</label>
                            <textarea type="text" className="form-control" id="exampleFormControlInput1" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)} disabled={action === 'view'} />
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-4'>
                            <input class="form-check-input" type="checkbox" checked={isSystemUser} onChange={(e) => setIsSystemUser(e.target.checked)} id="flexCheckDefault" disabled={action === 'view'} />
                            <label class="form-check-label" for="flexCheckDefault" style={{ marginLeft: 10 }}>
                                System User
                            </label>
                        </div>
                    </div>
                    {isSystemUser && <div className='row mt-2'>
                        <div className="col-4">
                            <label for="exampleFormControlInput1" className={style.input_label}>Role</label>
                            <select className="form-control" id="exampleFormControlSelect1" placeholder='Role' value={role} onChange={(e) => setRole(e.target.value)} disabled={action === 'view'}>
                                <option>Select role</option>
                                { systemRoles.map(role => <option value={role._id}>{role.name}</option>) }
                            </select>
                        </div>
                    </div>}
                    <div className='row mt-2'>
                        <div className='col-4'>
                            {uploadImages && <div className="mt-2 d-flex flex-wrap">
                                {[uploadImages].map(image =>
                                    <div
                                        style={{ position: "relative", maxWidth: "75%" }}
                                    >
                                        <img style={{ width: "100%", height: 'auto', marginBottom: 10, marginRight: 10, cursor: "pointer" }} src={image} />
                                        <IconButton
                                            onClick={() => removeImage(image)}
                                            sx={{
                                                position: 'absolute',
                                                right: 0,
                                                left: 0,
                                                top: 0,
                                                bottom: 0,
                                                color: "black",
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                )}
                            </div>}
                        </div>
                    </div>
                </DialogContent>
                {action !== 'view' && <DialogActions>
                    <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
                    {action === "create" && <button className="btn btn-primary" onClick={createStaff} disabled={isUploadingImage}>
                        Create
                    </button>}
                    {action === "edit" && <button className="btn btn-primary" onClick={updateStaff} disabled={isUploadingImage}>
                        Update
                    </button>}
                </DialogActions>}
            </BootstrapDialog>
        </>
    );
}
