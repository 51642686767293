import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { Dialog, DialogTitle, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import { steps, stepColors } from '../../../config/config';
import { ordersActions } from '../../../store/ordersSlice';
import * as ordersService from "../../../services/orderService";

const DeliverUpdate = (props) => {
    const dispatch = useDispatch();
    const { onClose, open, orderId } = props;
    const orders = useSelector((state) => state.orders.orders);
    const [openModal, setOpenModal] = useState(false);
    const [nextStep, setNextStep] = useState(0);
    

    useEffect(() => {
        if (orderId) {
            const order = orders.find(el => el._id === orderId);
            const orderStep = steps.indexOf(order.status);
            setNextStep(orderStep + 1);
            console.log(orderStep);
        }
    }, [orderId]);

    useEffect(() => {
        setOpenModal(open);
    }, [open]);
  
    const handleClose = () => {
     dispatch(ordersActions.deliverModalHandler(false));
    };
  
    const handleListItemClick = (value) => {
      onClose(value);
    };

    const updateOrder = () => {
        dispatch(ordersService.updateOrderStatus(steps[nextStep], orderId));
    }
  
    return (
      <Dialog maxWidth={"xs"} fullWidth={true} onClose={handleClose} open={openModal}>
        <DialogTitle sx={{ m: 0, p: 2, backgroundColor: "#1976d2", color: "white" }} id="customized-dialog-title">
            Update Order Details
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: "white",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
        <List sx={{ pt: 0 }}>
          <ListItem disableGutters>
            <ListItemButton
              autoFocus
              onClick={updateOrder}
             
            >
              <ListItemAvatar>
                <Avatar  sx={{backgroundColor: stepColors[nextStep]}}>
                  <AddIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={`Select to update to the ${steps[nextStep]} status`} />
            </ListItemButton>
          </ListItem>
        </List>
      </Dialog>
    );
  }

  export default DeliverUpdate;
  