import { TableCell } from "@mui/material";
import { Delete, Edit, RemoveRedEyeRounded } from "@mui/icons-material";
import { useSelector } from "react-redux";


const TableCellAction = ({ viewItem, editItem, deleteItem, module }) => {
    const systemAccess = useSelector((state) => state.auth.access);
    return (
        <TableCell align="right">
            {systemAccess.some(access => access.module === module && access.view) && viewItem && <RemoveRedEyeRounded onClick={viewItem} fontSize="small" sx={{ color: "blue", cursor: "pointer" }} />}
            {systemAccess.some(access => access.module === module && access.edit) && <Edit onClick={editItem} fontSize="small" sx={{ color: "green", cursor: "pointer", marginLeft: 1 }} />}
            {systemAccess.some(access => access.module === module && access.delete) && deleteItem && <Delete onClick={deleteItem} fontSize="small" sx={{ color: "red", cursor: "pointer", marginLeft: 1 }} />}
        </TableCell>
    );
};

export default TableCellAction;

// const TableCellAction = ({ onClick }) => {
//     // const onClick = () => {
//     //     viewOrder();
//     // }

//     return (
//         <TableCell align="right">
//             <RemoveRedEyeRounded onClick={onClick} fontSize="small" sx={{ color: "blue", cursor: "pointer" }} />
//             {/* <Edit onClick={() => editOrder(order)} fontSize="small" sx={{ color: "green", cursor: "pointer", marginLeft: 1 }} /> */}
//             {/* <Delete onClick={() => deleteHandler(order._id)} fontSize="small" sx={{ color: "red", cursor: "pointer", marginLeft: 1 }} /> */}
//         </TableCell>
//     );
// };

// export default TableCellAction;