import React, { useState, useEffect } from 'react'
import './finance.css';
import { BedroomChild, BookOnline, Login, Logout } from '@mui/icons-material';
import * as financeService from "../../services/financeService";
import moment from 'moment';

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Box, Tab, Tabs } from '@mui/material';

import MonthlyReportChart from './report';

export default function Finance() {
  const [counts, setCounts] = useState({});
  // const [counts, setCounts] = useState({
  //   totalSales: 0,
  //   customers: 0,
  //   orders: 0,
  //   salesItems: 0,
  //   stocks: 0,
  // });

  const userTimezone = Intl.DateTimeFormat(undefined, { timeZoneName: 'long' }).resolvedOptions().timeZone;
  const timezone = encodeURIComponent(userTimezone);
  const [monthlyReport, setMonthlyReport] = useState([]);

  const [value, setValue] = React.useState(0);
  const [reportTab, setReportTab] = useState(0);
  const [reportType, setReportType] = useState("salesOnShopQuantityReport");
  const [reportName, setReportName] = useState("Monthly Sales Overview: Shop Sale Quantity and Counts");

  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const handleSelect = (ranges) => {
    const { selection: { startDate, endDate } } = ranges;
    setDateRange({
      startDate: startDate ?? new Date(),
      endDate: endDate ?? new Date(),
      key: 'selection',
    });
  }

  useEffect(() => {
    const { startDate, endDate } = dateRange;
    financeService.getCounts({ startDate: moment(startDate).format("yyyy-MM-DD"), endDate: moment(endDate).format("yyyy-MM-DD"), timezone })
      .then(res => {
        const data = res.data;
        // setCounts({
        //   totalSales: data.totalSales,
        //   customers: data.customers,
        //   orders: data.orders,
        //   salesItems: data.salesItems,
        //   stocks: data.stocks,
        // })
        setCounts(res.data);
        console.log(res.data);
      })
      .catch(err => {
        console.log(err);
      })
  }, [dateRange]);

  useEffect(() => {
    if (value === 1) {
      financeService.getReports({ reportType })
        .then(res => {
          const data = res.data;
          setMonthlyReport(data);
        })
        .catch(err => {
          console.log(err);
        })
    }
  }, [value, reportTab]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const reportsHandleChange = (event, newValue) => {
    if (newValue === 0) {
      setReportName("Monthly Sales Overview: Shop Sale Quantity and Counts");
      setReportType("salesOnShopQuantityReport");
    }
    if (newValue === 1) {
      setReportName("Monthly Sales Overview: Online Sale Quantity and Counts");
      setReportType("ordersQuantityReport");
    }
    if (newValue === 2) {
      setReportName("Integrated Monthly Sales Report: Online and Shop Sale Quantity and Counts");
      setReportType("salesOnShopAndOrdersQuantityReport");
    }
    if (newValue === 3) {
      setReportName("Monthly Revenue Breakdown: Shop Sale Total Amount");
      setReportType("salesOnShopAmountReport");
    }
    if (newValue === 4) {
      setReportName("Monthly Revenue Breakdown: Online Sale Total Amount");
      setReportType("ordersAmountReport");
    }
    if (newValue === 5) {
      setReportName("Unified Monthly Sales Analysis: Online and Shop Total Amounts");
      setReportType("salesOnShopAndOrdersAmountReport");
    }

    setReportTab(newValue);
  };

  console.log(value);

  return (
    <div className='background_container'>
      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Tabs value={value} onChange={handleChange} centered>
          <Tab label="Sales Overview" />
          <Tab label="Compare Sales" />
          <Tab label="Reports Download" />
        </Tabs>
      </Box>

      {value === 0 && <div className="dashboard_shapes mt-4">
        <div style={{ width: "47%" }}>
          <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
            <div className='d_sqt' style={{ width: "45%", marginBottom: 20 }}>
              <div className='d_box'>
                <div style={{ fontSize: 16 }}>Total Sales</div>
                <div><BookOnline /></div>
              </div>
              <div className='dash_box'>{(counts?.totalOnlineSales ?? 0) + (counts?.totalOnShopSales ?? 0)}</div>
            </div>

            <div className='d_sqt' style={{ width: "45%", marginBottom: 20 }}>
              <div className='d_box'>
                <div style={{ fontSize: 16 }}>Total Profit</div>
                <div><Login /></div>
              </div>
              <div className='dash_box'>{(counts?.totalOnlineProfit ?? 0) + (counts?.totalOnShopProfit ?? 0)}</div>
            </div>
          </div>

          <div style={{width: "90%"}}>
            <DateRangePicker
              ranges={[dateRange]}
              onChange={handleSelect}
            />

          </div>
        </div>

        <div style={{ width: "47%" }}>
          <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
            <div className='d_sqs' style={{ width: "45%", marginBottom: 20 }}>
              <div className='d_box'>
                <div style={{ fontSize: 16 }}>On Shop Sales</div>
                <div><BookOnline /></div>
              </div>
              <div className='dash_box'>{counts?.totalOnShopSales ?? 0}</div>
            </div>

            <div className='d_sqs' style={{ width: "45%", marginBottom: 20 }}>
              <div className='d_box'>
                <div style={{ fontSize: 16 }}>On Shop Profit</div>
                <div><Login /></div>
              </div>
              <div className='dash_box'>{counts?.totalOnShopProfit ?? 0}</div>
            </div>

            <div className='d_sq' style={{ width: "45%", marginBottom: 20 }}>
              <div className='d_box'>
                <div style={{ fontSize: 16 }}>Online Sales</div>
                <div><Logout /></div>
              </div>
              <div className='dash_box'>{counts?.totalOnlineSales ?? 0}</div>
            </div>

            <div className='d_sq' style={{ width: "45%", marginBottom: 20 }}>
              <div className='d_box'>
                <div style={{ fontSize: 16 }}>Online Profit</div>
                <div><Logout /></div>
              </div>
              <div className='dash_box'>{counts?.totalOnlineProfit ?? 0}</div>
            </div>

            <div className='d_sqf' style={{ width: "45%", marginBottom: 20 }}>
              <div className='d_box'>
                <div style={{ fontSize: 16 }}>Online Orders</div>
                <div><Logout /></div>
              </div>
              <div className='dash_box'>{counts?.onlineOrdersCount ?? 0}</div>
            </div>

            <div className='d_sqf' style={{ width: "45%", marginBottom: 20 }}>
              <div className='d_box'>
                <div style={{ fontSize: 16 }}>Shop Orders</div>
                <div><Logout /></div>
              </div>
              <div className='dash_box'>{counts?.shopOrdersCount ?? 0}</div>
            </div>

            <div className='d_sqf' style={{ width: "45%", marginBottom: 20 }}>
              <div className='d_box'>
                <div style={{ fontSize: 16 }}>Stock Counts</div>
                <div><Logout /></div>
              </div>
              <div className='dash_box'>{counts?.stocks ?? 0}</div>
            </div>

            <div className='d_sqf' style={{ width: "45%", marginBottom: 20 }}>
              <div className='d_box'>
                <div style={{ fontSize: 16 }}>Registered Customer</div>
                <div><Logout /></div>
              </div>
              <div className='dash_box'>{counts?.customers ?? 0}</div>
            </div>
          </div>
        </div>
      </div>}

      {value === 1 && <div className="mt-4 p-4">

        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <Tabs value={reportTab} onChange={reportsHandleChange}>
            <Tab label="Shop Quantity" />
            <Tab label="Online Quantity" />
            <Tab label="Sales and Order Quantity" />
            <Tab label="Shop Amount" />
            <Tab label="Online Amount" />
            <Tab label="Shop and Online Amount" />
          </Tabs>
        </Box>

        <MonthlyReportChart monthlyReport={monthlyReport} reportName={reportName} />
      </div>}

    </div>
  )
}

