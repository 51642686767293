// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA-PKbBJlr_lmSBwNS9k1e51zHVQUbrNxM",
  authDomain: "shop-front-end.firebaseapp.com",
  projectId: "shop-front-end",
  storageBucket: "shop-front-end.appspot.com",
  messagingSenderId: "384299359256",
  appId: "1:384299359256:web:05b6990296b7c54a90376d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
