import { TableHead, TableRow, TableCell } from "@mui/material";

const TableHeadSection = ({titles}) => {
    return (
        <TableHead>
            <TableRow sx={{ backgroundColor: "#0000000d", fontWeight: 700 }}>
                {titles.map((el, i) => <TableCell sx={{ fontWeight: 700,  textAlign: i === titles?.length - 1 ? "right" : "start" }} >{el}</TableCell>)}
                {/* {titles.map((el, i) => <TableCell sx={{ fontWeight: 700,  textAlign: i === titles.length - 1 ? "right" : i === 0 ? "start" : "center" }} >{el}</TableCell>)} */}
            </TableRow>
        </TableHead>
    );
};

export default TableHeadSection;