import store from "../store/store";
import * as ProductService from '../services/productServices';
import * as CustomerService from '../services/customerService';
import * as SystemService from '../services/systemRolesService';

export const refreshProducts = () => {
    return store.dispatch(ProductService.getProducts());
}

export const refreshCustomers = () => {
    return store.dispatch(CustomerService.getCustomerList());
}

export const refreshRoles = () => {
    return store.dispatch(SystemService.getSystemRoles());
}