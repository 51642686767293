import { storage } from '../firebase';
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';

export const uploadFileToFirebase = async (filePath, file) => {
    const imageRef = ref(storage, filePath);
    const uploadOnTask = uploadBytesResumable(imageRef, file);
    console.log(uploadOnTask);

    return new Promise((resolve, reject) => {
        uploadOnTask.on(
            "state_changed",
            snapshot => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log(progress);
            },
            error => {
                console.error(error);
                reject(error);
            },
            () => {
                getDownloadURL(uploadOnTask.snapshot.ref)
                    .then(downloadUrl => {
                        console.log(downloadUrl);
                        resolve(downloadUrl);
                    })
                    .catch(error => {
                        console.error(error);
                        reject(error);
                    });
            }
        );
    });
};

export const deleteFile = (url) => {
    const fileName = getFileNameFromUrl(url);
    const desertRef = ref(storage, fileName);
    return new Promise((resolve, reject) => {
        deleteObject(desertRef).then(() => {
            console.log("deleted");
            resolve();
        }).catch((error) => {
            console.error("Error deleting file:", error);
        });
    });
}

function getFileNameFromUrl(url) {
    const segments = url.split("/");
    const fileName = segments[segments.length - 1];
    let decodedFileName = decodeURIComponent(fileName);

    const index = decodedFileName.indexOf("?");
    if (index >= 0) {
        decodedFileName = decodedFileName.substring(0, index);
    }

    return decodedFileName;
}