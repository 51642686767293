import React from 'react'
import './received-loan.css';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { getLoan, addreceive, deletereceiver, editreceive } from '../../services/loanServices';
import { useDispatch, useSelector } from "react-redux";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


export default function ReceivedLoan() {

    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [amount, setAmount] = useState("");
    const [dateofReceive, setDateofReceive] = useState("");
    const [dataArray, setDataArray] = useState([]);
    const [id, setId] = useState("");

    const addreceiveHandle = () => {
        dispatch(addreceive(name, amount, dateofReceive));
        setName("");
        setAmount("");
        setDateofReceive("");
    };

    const editreceiveHandle = () => {
        dispatch(editreceive(name, amount, dateofReceive, id));
    }


    const editrlHandler = (ReceivedLoan) => {
        // console.log(loan);
        console.log(ReceivedLoan.name);
        console.log(ReceivedLoan.amount);
        console.log(ReceivedLoan.dateofReceive);

        setName(ReceivedLoan.name);
        setAmount(ReceivedLoan.amount);
        setDateofReceive(ReceivedLoan.dateofReceive);

        setId(ReceivedLoan._id)
    }

    const deleterlHandler = (ReceivedLoan) => {
        dispatch(deletereceiver(ReceivedLoan));

    }

    useEffect(() => {
        // getLoan();
        dispatch(getLoan());
    }, [])

    const loan = useSelector((state) => state.loan.loan);

    return (
        <div className='rec_container'>
            <div className='re_ln'>
                <div class="form-group">
                    <label for="exampleInputEmail1" style={{ color: "red" }}>Name</label>
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                <div class="form-group">
                    <label for="exampleInputPassword1" style={{ color: "green" }}>Amount</label>
                    <input type="number" class="form-control" id="exampleInputPassword1" placeholder="Amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                </div>
                <div class="form-group">
                    <label for="exampleInputPassword1" style={{ color: "blue" }}>Date of Receive</label>
                    <input type="date" class="form-control" id="exampleInputPassword1" placeholder="Date of Receive" value={dateofReceive} onChange={(e) => setDateofReceive(e.target.value)} />
                </div>
                <button type="button" style={{ width: "100px", height: "40px" }} onClick={addreceiveHandle} class="btn btn-outline-warning">Submit</button>
                <button type="button" style={{ width: "100px", height: "40px" }} onClick={editreceiveHandle} class="btn btn-outline-danger">Edit</button>
            </div>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 350 }} aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: "#0000000d", fontWeight: 700 }}>
                            <TableCell sx={{ fontWeight: 700 }}>Name</TableCell>
                            <TableCell sx={{ fontWeight: 700 }}>Amount</TableCell>
                            <TableCell sx={{ fontWeight: 700 }}>Date</TableCell>
                            <TableCell sx={{ fontWeight: 700 }}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loan.map((ReceivedLoan) => (
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                {ReceivedLoan.name}
                                    {ReceivedLoan.amount}
                                    {ReceivedLoan.date}
                                </TableCell>
                                <TableCell ></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                                <td className='btn-ln'>
                                <button class="btn" onClick={() => editrlHandler(ReceivedLoan)}><i class="fa fa-edit"></i></button>
                                    <button class="btn" onClick={() => deleterlHandler(ReceivedLoan)}><i class="fa fa-trash"></i></button>
                                </td>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <div className='rec_table'>
                <table class="table table-success table-striped-colums table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Date of Receive</th>
                            <th scope='col'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loan.map(ReceivedLoan => {
                            return <tr>
                                <td>{ReceivedLoan.name}</td>
                                <td>{ReceivedLoan.amount}</td>
                                <td>{ReceivedLoan.date}</td> <td className='btn-ln'>
                                    <button class="btn" onClick={() => editrlHandler(ReceivedLoan)}><i class="fa fa-edit"></i></button>
                                    <button class="btn" onClick={() => deleterlHandler(ReceivedLoan)}><i class="fa fa-trash"></i></button>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div> */}

        </div >
    )
}
