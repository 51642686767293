import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import * as productService from "../../services/productServices";
import * as settingsService from "../../services/settingsService";
import * as categoryService from "../../services/categoryService";
import style from './settings.module.css';
import { Circles } from 'react-loader-spinner';
import { uploadFileToFirebase } from '../../util/firebaseService';
import { resizeImage } from '../../util/imageResizer';
import { errorNotify } from '../../config/notify';

const Settings = () => {
  const dispatch = useDispatch();
  const banners = useSelector((state) => state.settings.banners);
  const categories = useSelector((state) => state.category.categories);

  const [isEdit, setIsEdit] = useState(false);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [uploadImage, setUploadImage] = useState("");
  const [file, setFile] = useState("");

  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const [selectedBanner, setSelectedBanner] = useState();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [color, setColor] = useState("");

  useEffect(() => {
    dispatch(settingsService.getBanners());
    dispatch(categoryService.getCategories());
  }, []);

  const imageUpload = async (event) => {
    const selectedFile = event.target.files[0];
    const resizedImage = await resizeImage(selectedFile, imageWidth, imageHeight);
    setIsUploadingImage(true);
    setFile({ file: resizedImage });
    setUploadImage("");
    uploadFileToFirebase(`banner/${selectedFile.name}-${new Date()}`, resizedImage)
      .then(downloadUrl => {
        setUploadImage(downloadUrl);
        setIsUploadingImage(false);
        event.target.value = null;
      })
      .catch(error => {
        console.error("Error uploading file:", error);
      });
  };

  const editHandler = (banner) => {
    setIsEdit(true);
    setSelectedBanner(banner._id);
    setImageHeight(banner.height);
    setImageWidth(banner.width);
    setTitle(banner.title);
    setDescription(banner.description);
    setUploadImage(banner.image);
    setColor(banner.color);
    setCategory(banner.category);
  }

  const updateBanner = () => {
    return errorNotify("Features are locked in the demo version.");
    const bannerJson = {
      title,
      description,
      image: uploadImage,
      color,
      category
    };
    dispatch(settingsService.updateBanner(selectedBanner, bannerJson));
    resetHandler();
  };

  const resetHandler = () => {
    setIsEdit(false);
    setSelectedBanner("");
    setImageHeight(0);
    setImageWidth(0);
    setTitle("");
    setDescription("");
    setUploadImage("");
  };

  return (
    <div className={style.container}>
      <div class="alert alert-primary my-2" role="alert">
        Here, you can modify the customer website's banner images, description, and redirect page linked to the item. Please note that this feature is locked for the demo application; however, in the live application, you can make modifications.
      </div>
      {banners.map(banner => {
        return (
          <div key={banner._id}>
            <div><b>{banner.name}</b></div>
            <div className="row mt-2">
              <div className="form-group col-6">
                <div className="row">
                  <div className="col-12">
                  <label for="exampleFormControlInput1" className={style.input_label}>Title</label>
                  <textarea type="text" maxLength={banner?.titleLimit} value={banner?._id === selectedBanner ? title : banner?.title} onChange={(e) => banner?._id === selectedBanner && setTitle(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="Tittle" disabled={banner?._id !== selectedBanner} />
                  </div>
                </div>
                <div className="row mt">
                  <div className="col-12">
                  <label for="exampleFormControlInput1" className={style.input_label}>Description</label>
                  <textarea type="text" maxLength={banner?.descriptionLimit} value={banner?._id === selectedBanner ? description : banner?.description} onChange={(e) => banner?._id === selectedBanner && setDescription(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="Description" disabled={banner?._id !== selectedBanner} />
                  </div>
                </div>
                <div className="row">

                </div>
              </div>

              <div className="form-group col-6">
                <div className="row">
                  <div className="col-8">
                    <div className="row">
                      <div className="col-12">
                        <label for="exampleFormControlInput1" className={style.input_label}>Text Color</label>
                        <input type="text" value={banner?._id === selectedBanner ? color : banner?.color} onChange={(e) => banner?._id === selectedBanner && setColor(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="Text Color" disabled={banner?._id !== selectedBanner} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <label for="exampleFormControlInput1" className={style.input_label}>Redirect to</label>
                        <select className="form-control" id="exampleFormControlSelect1" placeholder='Category' value={banner?._id === selectedBanner ? category : banner?.category} onChange={(e) => banner?._id === selectedBanner && setCategory(e.target.value)} disabled={banner?._id !== selectedBanner}>
                          <option>Select category</option>
                          {categories.map(category => {
                            return <option value={category._id}>{category.name}</option>
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="mt-2">
                      <div className="col-12">
                        <div className='d-flex align-items-center'>
                          <input type="file" className="form-control" id="exampleFormControlInput1" placeholder="Image" accept="image/*" onChange={(e) => banner?._id === selectedBanner && imageUpload(e)} disabled={banner?._id !== selectedBanner || true} />
                          <Circles
                            height="30"
                            color="#4fa94d"
                            ariaLabel="circles-loading"
                            visible={isUploadingImage && banner?._id === selectedBanner}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="row mt-4">
                      <div>
                        <img src={banner?._id === selectedBanner ? uploadImage : banner?.image} width={100} height={100} />
                      </div>
                    </div>
                    <div className="row mt-2">
                    <div className="col-12">
                        {(banner?._id !== selectedBanner) && <button style={{ height: "40px", fontSize: 14 }} type="button" className="btn btn-primary" onClick={() => editHandler(banner)} disabled={isUploadingImage}>Edit</button>}
                        {isEdit && banner?._id === selectedBanner && <button style={{ height: "40px", fontSize: 14, marginRight: 10 }} type="button" onClick={updateBanner} disabled={isUploadingImage} className="btn btn-primary" >Save</button>}
                        {(isEdit) && banner?._id === selectedBanner && <button style={{ height: "40px", fontSize: 14 }} type="button" className="btn btn-secondary" onClick={resetHandler}>Cancel</button>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
        )
      })}
    </div >
  );
};

export default Settings;