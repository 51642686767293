import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Select, { StylesConfig } from 'react-select';
import makeAnimated from 'react-select/animated';
import chroma from 'chroma-js';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { Circles } from 'react-loader-spinner';

import { deleteFile, uploadFileToFirebase } from '../../../util/firebaseService';
import * as stockService from "../../../services/stockService";
import style from "./addOrEditStock.module.css";
import { editstock } from '../../../services/stockService';
import moment from 'moment/moment';
import { stockActions } from '../../../store/stockSlice';
import { warningNotify } from '../../../config/notify';
import { Delete, Edit } from '@mui/icons-material';
import { refreshProducts } from '../../refresh';
import { Divider } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));





export default function AddOrEditProduct({ title, modalOpen, action, modalHandler, stockId }) {
    const dispatch = useDispatch();
    const products = useSelector((state) => state.product.products);
    const stocks = useSelector((state) => state.stock.stocks);

    const [name, setName] = useState("");
    const [price, setPrice] = useState();
    const [quantity, setQuantity] = useState("");
    const [product, setProduct] = useState("");
    const [date, setDate] = useState("");
    const [uploadImages, setUploadImages] = useState(undefined);
    const [file, setFile] = useState("");
    const [isUploadingImage, setIsUploadingImage] = useState(false);
    const [originalPrice, setOriginalPrice] = useState();

    const [deletedItems, setDeletedItems] = useState([]);

    const [modifiedProductsArray, setModifiedProductsArray] = useState([]);
    // const [selectedStock, setSelectedStock] = useState({});

    const [size, setSize] = useState("");
    const [sizes, setSizes] = useState([]);
    const [items, setItems] = useState([]);
    const [iseEditItem, setIseEditItem] = useState(false);
    const [itemId, setItemId] = useState("");

    const [color, setColor] = useState("");

    const [colors, setColors] = useState([]);
    const [productItems, setProductItems] = useState([]);

  

    useEffect(() => {
        refreshProducts();
    }, []);

    useEffect(() => {
        const newProductsArray = [];
        products.forEach(product => {
            newProductsArray.push({ value: product._id, label: product.name });
        });
        setModifiedProductsArray(newProductsArray);
    }, [products])

    useEffect(() => {
        let totalPrice = 0;
        items.forEach(el => totalPrice += el.originalPrice * el.quantity);
        setPrice(totalPrice);
    }, [items]);

    useEffect(() => {
        if (stockId) {
            const stock = stocks.find(el => el._id === stockId);
            setName(stock.name);
            setQuantity(stock.quantity);
            setPrice(stock.price);
            setDate(moment(stock.date).format("yyyy-MM-DD"));
            setUploadImages(stock.images);
            setItems(stock.items);
        }
    }, [stockId]);

    const handleClose = () => {
        dispatch(stockActions.modalHandler(false));
    };

    const createStock = () => {
        if(!items.length) {
            return warningNotify("Please add minium one stock item.");
        }
        const stockJson = {
            name,
            // product,
            images: uploadImages,
            price,
            // quantity,
            items,
            date,
        }
        console.log(stockJson);
        dispatch(stockService.createStock(stockJson));
    }

    const updateStock = () => {
        const stockJson = {
            name,
            // product,
            images: uploadImages,
            price,
            // quantity,
            items,
            date,
            deletedItems
        }
        console.log(stockJson, stockId);
        dispatch(stockService.updateStock(stockJson, stockId));
    }

    const imageUpload = async (event) => {
        const selectedFile = event.target.files[0];
        console.log(event.target.files[0]);
        setIsUploadingImage(true);
        setFile({ file: selectedFile });
        uploadFileToFirebase(`stocks/${selectedFile.name}-${new Date()}`, selectedFile)
            .then(downloadUrl => {
                setUploadImages(downloadUrl);
                setIsUploadingImage(false);
            })
            .catch(error => {
                console.error("Error uploading file:", error);
            });
    };

    const removeImage = (url) => {
        deleteFile(url)
            .then(() => {
                // const filterImages = uploadImages.filter(image => image != url);
                setUploadImages(undefined);
            })
            .catch(error => {
                console.error("Error deleting file:", error);
            });
    }

    const addItem = () => {
        if (!product?.value) {
            return warningNotify("Please select a product.");
        }
        // if (!size) {
        //     return warningNotify("Please select a size.");
        // }
        // if (!color) {
        //     return warningNotify("Please select color.");
        // }
        if(!quantity) {
            return warningNotify("Please enter the quantity.");
        }
        if(!originalPrice) {
            return warningNotify("Please enter the original price.");
        }
        if (!iseEditItem && items.some(el => el.product.value === product.value)) {
            return warningNotify("Already added product.");
        }
        if (iseEditItem) {
            const newItem = {
                size,
                color,
                quantity: +quantity,
                originalPrice,
                product,
                isEdit: true,
            };
            if (itemId) {
                newItem._id = itemId;
            }
            const filterItems = items.filter(el => el.product.value !== product.value);
            setItems([...filterItems, newItem]);
        } else {
            setItems([...items, {
                size: size,
                quantity: +quantity,
                color,
                originalPrice,
                product,
                runningQuantity: +quantity,
            }]);
        }
        resetItem();
    }

    const editItem = (item) => {
        // setSize(item.size);
        // setColor(item.color);
        setQuantity(+item.quantity);
        setOriginalPrice(+item.originalPrice);
        setProduct(item.product);
        setIseEditItem(true);
        if (item?._id) {
            setItemId(item?._id);
        }
    }

    const resetItem = () => {
        // setSize("");
        // setColor("");
        setQuantity("");
        setOriginalPrice("");
        setIseEditItem(false);
        setProduct("");
        setItemId("");
    };

    const removeItem = (item) => {
        console.log(item);
        console.log(items);
        if(item.quantity !== item.runningQuantity) {
            return warningNotify("You cannot remove a stock item that has already been sold.");
        }
        const filterItems = items.filter(el => el.product.value !== item.product.value);
        setItems([...filterItems]);
        if (item?._id) {
            setDeletedItems([...deletedItems, item._id]);
        }
    }

    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={modalOpen}
                maxWidth="xl"
                fullWidth={true}
            >
                <DialogTitle sx={{ m: 0, p: 2, backgroundColor: "#1976d2", color: "white" }} id="customized-dialog-title">
                    {title}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: "white",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ minHeight: 500 }} dividers>
                    <div className='row'>
                        <div className="col-3">
                            <label for="exampleFormControlInput1" className={style.input_label}>Name</label>
                            <input  type="text" className="form-control" id="exampleFormControlInput1" placeholder="Stock Name" value={name} onChange={(e) => setName(e.target.value)} disabled={action === 'view'} />
                        </div>
                        <div className="col-3">
                            <label for="exampleFormControlInput1" className={style.input_label}>Bill Amount</label>
                            <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Amount" value={price} disabled={true} />
                        </div>
                        <div className="col-3">
                            <label for="exampleFormControlInput1" className={style.input_label}>Date</label>
                            <input type="date" className="form-control" id="exampleFormControlInput1" placeholder="Date" value={date} onChange={(e) => setDate(e.target.value)} disabled={action === 'view'} />
                        </div>

                        <div className="col-3">
                            <label for="exampleFormControlInput1" className={style.input_label}>Bill/Receipt</label>
                            <div className='d-flex align-items-center'>
                                <input type="file" className="form-control" id="exampleFormControlInput1" placeholder="Image" accept="image/*" onChange={imageUpload} disabled={action === 'view'} />
                                <Circles
                                    height="30"
                                    color="#4fa94d"
                                    ariaLabel="circles-loading"
                                    visible={isUploadingImage}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-4" style={{color: "blue", fontSize: 14, fontWeight: 600}}>
                        <Divider>Add stock list</Divider>
                    </div>
                    <div className="row mt-2">
                        <div className="col-4" style={{ width: "23%" }}>
                            <label for="exampleFormControlInput1" className={style.input_label}>Product</label>
                            <Select
                                options={modifiedProductsArray}
                                isSearchable={true}
                                placeholder="Search by product"
                                value={product}
                                onChange={(product) => {
                                    setProduct(product);
                                }}
                                isDisabled={iseEditItem}
                            />
                        </div>
                        {/* <div className="col-3" style={{ width: "16%" }}>
                            <label for="exampleFormControlInput1" className={style.input_label}>Size</label>
                            <select disabled={action === 'view' || iseEditItem} className="form-control" id="exampleFormControlSelect1" placeholder='Product' value={size} onChange={(e) => setSize(e.target.value)}>
                                <option>Select size</option>
                                {sizes.map(el => {
                                    return <option value={el}>{el}</option>
                                })}
                            </select>
                        </div>
                        <div className="col-3" style={{ width: "16%" }}>
                            <label for="exampleFormControlInput1" className={style.input_label}>Color</label>
                            <select disabled={action === 'view' || iseEditItem} className="form-control" id="exampleFormControlSelect1" placeholder='Select Color' value={color} onChange={(e) => setColor(e.target.value)}>
                                <option>Select color</option>
                                {colors.map(color => {
                                    return <option value={color}>{color}</option>
                                })}
                            </select>
                        </div> */}
                        <div className="col-3" style={{ width: "16%" }}>
                            <label for="exampleFormControlInput1" className={style.input_label}>Quantity</label>
                            <input 
                            disabled={action === 'view'} type="number" className="form-control" id="exampleFormControlInput1" placeholder="Quantity" value={quantity} onChange={(e) => setQuantity(e.target.value > 0 && e.target.value)} />
                        </div>
                        <div className="col-3" style={{ width: "16%" }}>
                            <label for="exampleFormControlInput1" className={style.input_label}>Original Price (One Item)</label>
                            <input 
                            type="number" className="form-control" id="exampleFormControlInput1" placeholder="Original price" value={originalPrice} onChange={(e) => setOriginalPrice(e.target.value > 0 ? e.target.value : 1)} disabled={action === 'view'} />
                        </div>
                        <div className="col-2" style={{ display: "flex", alignItems: "center", width: "13%" }}>
                            <button disabled={action === 'view'} className="btn btn-primary mt-4" style={{ fontSize: 10 }} onClick={addItem}>{iseEditItem ? "Update" : "Add"}</button>
                            {iseEditItem && <button disabled={action === 'view'} className="btn btn-secondary mt-4" style={{ fontSize: 10, marginLeft: "10px" }} onClick={() => resetItem()}>Cancel</button>}
                        </div>
                    </div>
                    {items.length > 0 && <div className='row mt-4'>
                        <div className="col-8">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Product</th>
                                        {/* <th scope="col">Size</th> */}
                                        {/* <th scope="col">Color</th> */}
                                        <th>Quantity</th>
                                        <th scope="col">Original Price</th>
                                        <th>Remaining Q</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map(item => <tr>
                                        <td>{item.product?.label}</td>
                                        {/* <td>{item.size}</td>
                                        <td style={{ display: "flex", alignItems: "center" }}>
                                            <div style={{ width: 50 }}>{item?.color}</div>
                                            <div style={{ backgroundColor: item?.color, width: 20, height: 20, borderRadius: "50%", border: "0.5px solid black", marginLeft: 10 }}></div>
                                        </td> */}
                                        <td>{item.quantity}</td>
                                        <td>{item.originalPrice}</td>
                                        <td>{item.runningQuantity}</td>
                                        <td>
                                            <Edit onClick={() => action !== 'view' && editItem(item)} fontSize="medium" sx={{ color: "green", cursor: "pointer", marginRight: "10px" }} />
                                            <Delete onClick={() => action !== 'view' && removeItem(item)} fontSize="medium" sx={{ color: "red", cursor: "pointer" }} />
                                        </td>
                                    </tr>)}
                                </tbody>
                            </table>

                        </div>
                    </div>}
                    <div className='row mt-4'>
                        {uploadImages && <div className="mt-2 d-flex flex-wrap">
                            {[uploadImages].map(image =>
                                <div
                                    style={{ position: "relative", maxWidth: "75%" }}
                                >
                                    <img style={{ width: "100%", height: 'auto', marginBottom: 10, marginRight: 10, cursor: "pointer" }} src={image} />
                                    <IconButton
                                        onClick={() => removeImage(image)}
                                        sx={{
                                            position: 'absolute',
                                            right: 0,
                                            left: 0,
                                            top: 0,
                                            bottom: 0,
                                            color: "black",
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            )}
                        </div>}
                    </div>
                </DialogContent>
                {action !== 'view' && <DialogActions>
                    <button type="button" class="btn btn-secondary" onClick={handleClose}>Close</button>
                    {action === "create" && <button className="btn btn-primary" onClick={createStock}>
                        Create
                    </button>}
                    {action === "edit" && <button className="btn btn-primary" onClick={updateStock}>
                        Update
                    </button>}
                </DialogActions>}
            </BootstrapDialog>
        </>
    );
}
