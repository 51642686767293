import axios from 'axios';
import { categoryActions } from "../store/categorySlice";
import { API_HOST } from '../config/config';
import { successNotify, errorNotify } from '../config/notify';
import { settingsActions } from '../store/settingsSlice';

export const getBanners = () => (dispatch) => {
    axios({
        method: 'get',
        url: API_HOST + '/settings/banners',
    }).then(res => {
        // dispatch(categoryActions.loadingHandler(false));
        dispatch(settingsActions.getBanners(res.data.data));
    }).catch(err => {
        console.log(err.response);
        errorNotify(err.response?.data?.message || err.message);
        // dispatch(categoryActions.loadingHandler(false));
    });
};

export const updateBanner = (id, json) => (dispatch) => {
    axios({
        method: 'put',
        url: API_HOST + `/settings/${id}/update-banner`,
        data: json
    }).then(res => {
        // dispatch(categoryActions.loadingHandler(false));
        successNotify("Successfully updated banner.");
        dispatch(getBanners());
    }).catch(err => {
        console.log(err.response);
        errorNotify(err.response?.data?.message || err.message);
        // dispatch(categoryActions.loadingHandler(false));
    });
};

// export const createCategory = (name, image) => (dispatch) => {
//     axios({
//         method: 'post',
//         url: API_HOST + '/categories',
//         data: {
//             name,
//             image
//         }
//     }).then(() => {
//         successNotify("Successfully created category.");
//         dispatch(categoryActions.modalHandler(false));
//         dispatch(categoryActions.loadingHandler(true));
//         dispatch(getCategories());
//     }).catch(err => {
//         console.log(err.response);
//         errorNotify(err.response?.data?.message || err.message);
//         dispatch(categoryActions.loadingHandler(false));
//     });
// };

// export const deleteCategory = (id, reason) => (dispatch) => {
//     axios({
//         method: 'delete',
//         url: API_HOST + `/categories/${id}`,
//         data: {
//             reason,
//         }
//     }).then(() => {
//         successNotify("Successfully deleted category.");
//         dispatch(categoryActions.modalHandler(false));
//         dispatch(categoryActions.loadingHandler(true));
//         dispatch(getCategories());
//     }).catch(err => {
//         console.log(err.response);
//         errorNotify(err.response?.data?.message || err.message);
//         dispatch(categoryActions.loadingHandler(false));
//     });
// };

// export const updateCategory = (name, image, id) => (dispatch) => {
//     axios({
//         method: 'put',
//         url: API_HOST + `/categories/${id}`,
//         data: {
//             name,
//             image
//         }
//     }).then(() => {
//         successNotify("Successfully updated category.");
//         dispatch(categoryActions.modalHandler(false));
//         dispatch(categoryActions.loadingHandler(true));
//         dispatch(getCategories());
//     }).catch(err => {
//         console.log(err.response);
//         errorNotify(err.response?.data?.message || err.message);
//         dispatch(categoryActions.loadingHandler(false));
//     });
// };

// export const updateVisible = (id, isVisible) => (dispatch) => {
//     axios({
//         method: 'put',
//         url: API_HOST + `/categories/${id}/update-visible`,
//         data: {
//             isVisible
//         }
//     }).then(() => {
//         if(isVisible) {
//             successNotify("Successfully updated to be visible to the customer.");
//         } else {
//             successNotify("Successfully updated to be invisible to the customer.");
//         }
//         dispatch(categoryActions.modalHandler(false));
//         dispatch(getCategories());
//     }).catch(err => {
//         console.log(err.response);
//         errorNotify(err.response?.data?.message || err.message);
//         dispatch(categoryActions.loadingHandler(false));
//     });
// };