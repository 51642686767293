import axios from 'axios';
import { productActions } from "../store/productSlice";
import { systemRolesActions } from "../store/systemRoles";
import { API_HOST } from '../config/config';
import { successNotify, errorNotify } from '../config/notify';
import { removeImage } from '../util/removeImages';
import axiosInstance from '../config/axiosInstance';

export const getSystemRolesAccess = (roleId) => (dispatch) => {
    axiosInstance({
        method: 'get',
        url: API_HOST + '/roles/system-access/' + roleId,
    }).then(res => {
        dispatch(systemRolesActions.getSystemRolesAccess(res.data.data));
        dispatch(systemRolesActions.loadingHandler(false));
    }).catch(err => {
        errorNotify(err.response?.data?.message || err.message);
        dispatch(systemRolesActions.loadingHandler(false));
    });
}

export const getSystemRoles = () => (dispatch) => {
    axiosInstance({
        method: 'get',
        url: API_HOST + '/roles/get',
    }).then(res => {
        dispatch(systemRolesActions.getSystemRoles(res.data.data));
        dispatch(systemRolesActions.loadingHandlerSystemRole(false));
    }).catch(err => {
        errorNotify(err.response?.data?.message || err.message);
        dispatch(systemRolesActions.loadingHandlerSystemRole(false));
    });
}

export const createSystemRole = (name) => (dispatch) => {
    axiosInstance({
        method: 'post',
        url: API_HOST + '/roles/create',
        data: { name }
    }).then(res => {
        dispatch(getSystemRoles());
        dispatch(systemRolesActions.loadingHandlerSystemRole(false));
    }).catch(err => {
        errorNotify(err.response?.data?.message || err.message);
        dispatch(systemRolesActions.loadingHandlerSystemRole(false));
    });
}

export const updateSystemRole = (roleId, name) => (dispatch) => {
    axiosInstance({
        method: 'put',
        url: API_HOST + '/roles/edit/' + roleId,
        data: { name }
    }).then(res => {
        dispatch(getSystemRoles(roleId));
        dispatch(systemRolesActions.loadingHandler(false));
    }).catch(err => {
        errorNotify(err.response?.data?.message || err.message);
        dispatch(systemRolesActions.loadingHandler(false));
    });
}

export const updateSystemRoleAccess = (data) => (dispatch) => {
    axiosInstance({
        method: 'post',
        url: API_HOST + '/roles/system-access',
        data: data
    }).then(res => {
        dispatch(getSystemRolesAccess(data.role));
        dispatch(systemRolesActions.loadingHandler(false));
    }).catch(err => {
        errorNotify(err.response?.data?.message || err.message);
        dispatch(systemRolesActions.loadingHandler(false));
    });
}

export const deleteSystemRole = (id, reason) => (dispatch) => {
    console.log(id)
    axiosInstance({
        method: 'delete',
        url: API_HOST + '/roles/delete/' + id,
        data: {
            reason,
        }
    }).then(() => {
        dispatch(systemRolesActions.loadingHandler(true));
        successNotify("Successfully deleted role.");
        dispatch(getSystemRoles());
    }).catch(err => {
        console.log(err.response);
        dispatch(systemRolesActions.loadingHandler(false));
        errorNotify(err.response?.data?.message || err.message);
    });
};

// export const createProduct = (productJson, removeImageArray) => (dispatch) => {
//     axiosInstance({
//         method: 'post',
//         url: API_HOST + '/products',
//         data: productJson
//     }).then(res => {
//         successNotify("Successfully created product.");
//         dispatch(productActions.modalHandler(false));
//         dispatch(productActions.loadingHandler(true));
//         dispatch(productActions.refreshItems());
//         if(removeImageArray?.length > 0) {
//             removeImage(removeImageArray);
//         }
//     }).catch(err => {
//         console.log(err.response);
//         errorNotify(err.response?.data?.message || err.message);
//         dispatch(productActions.loadingHandler(false));
//     });

// }

// export const updateProduct = (productJson, id, removeImageArray) => (dispatch) => {
//     axiosInstance({
//         method: 'put',
//         url: API_HOST + `/products/${id}`,
//         data: productJson
//     }).then(res => {
//         successNotify("Successfully updated product.");
//         dispatch(productActions.modalHandler(false));
//         dispatch(productActions.loadingHandler(true));
//         dispatch(productActions.refreshItems());
//         if(removeImageArray?.length > 0) {
//             removeImage(removeImageArray);
//         }
//     }).catch(err => {
//         console.log(err.response);
//         errorNotify(err.response?.data?.message || err.message);
//         dispatch(productActions.loadingHandler(false));
//     });
// }

// export const deleteProduct = (id, reason, previousImage) => (dispatch) => {
//     console.log(id)
//     axiosInstance({
//         method: 'delete',
//         url: API_HOST + `/products/${id}`,
//         data: {
//             reason,
//         }
//     }).then(() => {
//         dispatch(productActions.loadingHandler(true));
//         successNotify("Successfully deleted product.");
//         dispatch(productActions.refreshItems());
//         if(previousImage?.length > 0) {
//             removeImage(previousImage);
//         }
//     }).catch(err => {
//         console.log(err.response);
//         dispatch(productActions.loadingHandler(false));
//         errorNotify(err.response?.data?.message || err.message);
//     });
// };

// export const updateVisible = (id, isVisible) => (dispatch) => {
//     axiosInstance({
//         method: 'put',
//         url: API_HOST + `/products/${id}/update-visible`,
//         data: {
//             isVisible
//         }
//     }).then(() => {
//         if(isVisible) {
//             successNotify("Successfully updated to be visible to the customer.");
//         } else {
//             successNotify("Successfully updated to be invisible to the customer.");
//         }
//         dispatch(productActions.refreshItems());
//     }).catch(err => {
//         console.log(err.response);
//         errorNotify(err.response?.data?.message || err.message);
//         dispatch(productActions.loadingHandler(false));
//     });
// };