import axios from 'axios';

import { API_HOST } from '../config/config';
import { ordersActions } from '../store/ordersSlice';
import { successNotify, errorNotify } from '../config/notify';
import axiosInstance from '../config/axiosInstance';

export const getOrders = (params) => (dispatch) => {
    axiosInstance({
        method: 'get',
        url: API_HOST + '/orders',
        params: params,
    }).then(res => {
        dispatch(ordersActions.getOrders(res.data?.data));
        dispatch(ordersActions.loadingHandler(false));
    })
        .catch(err => {
            console.log(err.response);
            errorNotify(err.response?.data?.message || err.message);
            dispatch(ordersActions.loadingHandler(false));
        });
};

export const createOrder = (orderJson) => (dispatch) => {
    axiosInstance({
        method: 'post',
        url: API_HOST + '/orders/admin',
        data: orderJson
    })
        .then(() => {
            successNotify("Successfully created order.");
            dispatch(ordersActions.modalHandler(false));
            dispatch(ordersActions.loadingHandler(true));
            dispatch(ordersActions.refreshItems());
        })
        .catch(err => {
            console.log(err.response);
            errorNotify(err.response?.data?.message || err.message);
            dispatch(ordersActions.loadingHandler(false));
        });
};

export const deleteOrder = (id, reason) => (dispatch) => {
    console.log(id)
    axiosInstance({
        method: 'delete',
        url: API_HOST + `/orders/admin/${id}`,
        data: {
            reason,
        }
    }).then(() => {
        dispatch(ordersActions.loadingHandler(true));
        successNotify("Successfully deleted order.");
        dispatch(ordersActions.refreshItems());
    })
        .catch(err => {
            console.log(err.response);
            dispatch(ordersActions.loadingHandler(false));
            errorNotify(err.response?.data?.message || err.message);
        });
};

export const updateOrder = (orderJson, id) => (dispatch) => {
    axiosInstance({
        method: 'put',
        url: API_HOST + `/orders/admin/${id}`,
        data: orderJson
    })
        .then(() => {
            successNotify("Successfully updated order.");
            dispatch(ordersActions.refreshItems());
            dispatch(ordersActions.modalHandler(false));
            dispatch(ordersActions.loadingHandler(true));
        })
        .catch(err => {
            console.log(err.response);
            errorNotify(err.response?.data?.message || err.message);
            dispatch(ordersActions.loadingHandler(false));
        });
};

export const updateOrderStatus = (status, id) => (dispatch) => {
    axiosInstance({
        method: 'put',
        url: API_HOST + `/orders/status-update/${id}`,
        data: {
            status
        }
    })
        .then(() => {
            successNotify("Successfully updated order status.");
            dispatch(ordersActions.refreshItems());
            dispatch(ordersActions.deliverModalHandler(false));
            dispatch(ordersActions.loadingHandler(true));
        })
        .catch(err => {
            console.log(err.response);
            errorNotify(err.response?.data?.message || err.message);
            dispatch(ordersActions.deliverModalHandler(true));
            dispatch(ordersActions.loadingHandler(false));
        });
};