
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, useNavigate, redirect, Navigate } from "react-router-dom";

import Header from './components/header/header';
import Login from './components/login/login';
import Stocks from './components/stocks/stocks';
import Orders from './components/orders/orders';
import Addloan from './components/add-loan/add-loan';
import Products from './components/products/products';
import Category from './components/category/category';
import Dashboard from './components/dashboard/dashboard';
import SidePanel from './components/sidePanel/sidePanel';
import Footer from './components/footer/footer';
import ReceivedLoan from './components/received-loan/received-loan';
import Staff from './components/staff/staff';
import Customer from './components/customer/customer';
import Settings from './components/settings/settings';
import { authAction } from './store/authSlice';
import './App.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MobilePage from './components/mobileView/mobileView';
import { Cancel, Close, CloseFullscreen, CloseOutlined, CloseRounded } from '@mui/icons-material';
import Finance from './components/finance/finance';
import SalesShop from './components/salesShop/salesShop';
import SystemRole from './components/systemRole/system';

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isEnableSizeWarningMessage, setIsEnableSizeWarningMessage] = useState(false);

  const isLogin = useSelector((state) => state.auth.isLogin);

  useEffect(() => {
    const isMobileDevice = window.innerWidth < 1200;
    if (isMobileDevice) {
      // navigate('/mobile-page');
    } else {
      if(window.innerWidth <= 1430) {
        setIsEnableSizeWarningMessage(true);
      } else {
        setIsEnableSizeWarningMessage(false);
      }

      if (isLogin || JSON.parse(localStorage.getItem("isLogin"))) {
        dispatch(authAction.isLogin());
      } else {
        navigate(`/login`);
      }
    }
  }, [isLogin]);

  return (
    <div>
      <ToastContainer />
      {!isLogin && <div className='app_container'>
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="mobile-page" element={<MobilePage />} />
        </Routes>
      </div>}

      {isLogin && <div className='app_container'>
        <Header />

        {isEnableSizeWarningMessage && <div class="alert alert-warning my-2 alert-dismissible fade show" role="alert">
          Your desktop screen size is a bit smaller for an optimal experience with our system.
          To enhance your experience, kindly consider zooming out your browser to <b>80% or 90%</b>. Thank you for your cooperation.
          <Cancel onClick={() => setIsEnableSizeWarningMessage(false)} fontSize="small" sx={{ color: "red", cursor: "pointer", marginLeft: 1 }} />
        </div>}

        <div className='app_mainNav'>
          <div className='app_item'>
            <SidePanel />
          </div>
          <div className='app_mainItem'>
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="categories" element={<Category />} />
              <Route path="stocks" element={<Stocks />} />
              <Route path="orders" element={<Orders />} />
              <Route path="sales-shop" element={<SalesShop />} />
              <Route path="products" element={<Products />} />
              <Route path="loan" element={<Addloan />} />
              <Route path="received-loan" element={<ReceivedLoan />} />
              <Route path="staff" element={<Staff />} />
              <Route path="customer" element={<Customer />} />
              <Route path="finance" element={<Finance />} />
              <Route path="settings" element={<Settings />} />
              <Route path="system-role" element={<SystemRole />} />
              {/* <Route path="mobile-page" element={<MobilePage />} /> */}
            </Routes>
          </div>
        </div>
      </div>}
    </div>
  );
}

export default App;
