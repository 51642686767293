import "./mobileView.css"

const MobilePage = () => {
    return (
        <div class="redirect-container">
            <h1>Access Restricted - Admin Application</h1>
            <p>This application is optimized for desktop or laptop use. Please use a larger screen to access the full functionality.</p>
        </div>
    );
};

export default MobilePage;