import React, { useState, useEffect } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { NavLink, Link } from 'react-router-dom';
import CategoryIcon from '@mui/icons-material/Category';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import AddIcon from '@mui/icons-material/Add';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import StoreIcon from '@mui/icons-material/Store';
import "./sidePanel.css"
import { BookOnline, Money, MoneyRounded, Person, Person2, Person3, Person4, PersonAdd, PersonAddAlt, Settings } from '@mui/icons-material';
import { useSelector } from 'react-redux';

const SidePanel = () => {
    const systemAccess = useSelector((state) => state.auth.access);
    const [activeLink, setActiveLink] = useState("");
    const activeStyle = {
        backgroundColor: "#1976d2",
        borderRadius: 5,
        color: "white"
    }
    const activeIconColor = {
        color: "white"
    }
    const iconColor = {
        color: "#1976d2"
    }

    useEffect(() => {
        const currentPath = window.location.pathname.substring(1);
        setActiveLink(currentPath);
    }, []);

    console.log(systemAccess);

    return (
        <div className='sidePanel_container'>
            <Box sx={{ width: '100%', borderRadius: '20' }}>
                <nav aria-label="main mailbox folders">
                    <List>

                        <ListItem component={NavLink} to="/dashboard" onClick={() => setActiveLink('dashboard')}>
                            <ListItemButton style={activeLink === "dashboard" ? activeStyle : {}}>
                                <ListItemIcon>
                                    <DashboardIcon style={activeLink === "dashboard" ? activeIconColor : iconColor} />
                                </ListItemIcon>
                                <ListItemText primary="Dashboard" />
                            </ListItemButton>
                        </ListItem>

                        {systemAccess.some(access => access.module === "Sales Shop" && access.view) && <ListItem component={Link} to="/sales-shop" onClick={() => setActiveLink('sales-shop')}>
                            <ListItemButton style={activeLink === "sales-shop" ? activeStyle : {}}>
                                <ListItemIcon>
                                    <PersonAdd style={activeLink === "sales-shop" ? activeIconColor : iconColor} />
                                </ListItemIcon>
                                <ListItemText primary="Sales Shop" />
                            </ListItemButton>
                        </ListItem>}

                        {/* {systemAccess.some(access => access.module === "Orders" && access.view) && <ListItem component={Link} to="/orders" onClick={() => setActiveLink('orders')}>
                            <ListItemButton style={activeLink === "orders" ? activeStyle : {}}>
                                <ListItemIcon>
                                    <BookOnline style={activeLink === "orders" ? activeIconColor : iconColor} />
                                </ListItemIcon>
                                <ListItemText primary="Orders" />
                            </ListItemButton>
                        </ListItem>} */}

                        {systemAccess.some(access => access.module === "Finance" && access.view) && <ListItem component={Link} to="/finance" onClick={() => setActiveLink('finance')}>
                            <ListItemButton style={activeLink === "finance" ? activeStyle : {}}>
                                <ListItemIcon>
                                    <MoneyRounded style={activeLink === "finance" ? activeIconColor : iconColor} />
                                </ListItemIcon>
                                <ListItemText primary="Finance" />
                            </ListItemButton>
                        </ListItem>}

                        {systemAccess.some(access => access.module === "Products" && access.view) && <ListItem component={Link} to="/products" onClick={() => setActiveLink('products')}>
                            <ListItemButton style={activeLink === "products" ? activeStyle : {}}>
                                <ListItemIcon>
                                    <ProductionQuantityLimitsIcon style={activeLink === "products" ? activeIconColor : iconColor} />
                                </ListItemIcon>
                                <ListItemText primary="Products" />
                            </ListItemButton>
                        </ListItem>}

                        {systemAccess.some(access => access.module === "Stocks" && access.view) && <ListItem component={Link} to="/stocks" onClick={() => setActiveLink('stocks')}>
                            <ListItemButton style={activeLink === "stocks" ? activeStyle : {}}>
                                <ListItemIcon>
                                    <StoreIcon style={activeLink === "stocks" ? activeIconColor : iconColor} />
                                </ListItemIcon>
                                <ListItemText primary="Stock" />
                            </ListItemButton>
                        </ListItem>}

                        {systemAccess.some(access => access.module === "Category" && access.view) && <ListItem component={Link} to="/categories" onClick={() => setActiveLink('categories')}>
                            <ListItemButton style={activeLink === "categories" ? activeStyle : {}}>
                                <ListItemIcon>
                                    <CategoryIcon style={activeLink === "categories" ? activeIconColor : iconColor} />
                                </ListItemIcon>
                                <ListItemText primary="Category" />
                            </ListItemButton>
                        </ListItem>}

                        {/* {systemAccess.some(access => access.module === "Customer" && access.view) && <ListItem component={Link} to="/customer" onClick={() => setActiveLink('customer')}>
                            <ListItemButton style={activeLink === "customer" ? activeStyle : {}}>
                                <ListItemIcon>
                                    <Person style={activeLink === "customer" ? activeIconColor : iconColor} />
                                </ListItemIcon>
                                <ListItemText primary="Customer" />
                            </ListItemButton>
                        </ListItem>} */}

                        {systemAccess.some(access => access.module === "Staff" && access.view) && <ListItem component={Link} to="/staff" onClick={() => setActiveLink('staff')}>
                            <ListItemButton style={activeLink === "staff" ? activeStyle : {}}>
                                <ListItemIcon>
                                    <PersonAdd style={activeLink === "staff" ? activeIconColor : iconColor} />
                                </ListItemIcon>
                                <ListItemText primary="Staff" />
                            </ListItemButton>
                        </ListItem>}

                        {systemAccess.some(access => access.module === "System Roles" && access.view) && <ListItem component={Link} to="/system-role" onClick={() => setActiveLink('system-role')}>
                            <ListItemButton style={activeLink === "system-role" ? activeStyle : {}}>
                                <ListItemIcon>
                                    <BookOnline style={activeLink === "system-role" ? activeIconColor : iconColor} />
                                </ListItemIcon>
                                <ListItemText primary="System Roles" />
                            </ListItemButton>
                        </ListItem>}

                        {/* {systemAccess.some(access => access.module === "Settings" && access.view) && <ListItem component={Link} to="/settings" onClick={() => setActiveLink('settings')}>
                            <ListItemButton style={activeLink === "settings" ? activeStyle : {}}>
                                <ListItemIcon>
                                    <Settings style={activeLink === "settings" ? activeIconColor : iconColor} />
                                </ListItemIcon>
                                <ListItemText primary="Settings" />
                            </ListItemButton>
                        </ListItem>} */}

                        {/* <ListItem component={Link} to="/loan" onClick={() => setActiveLink('loan')}>
                            <ListItemButton style={activeLink === "loan" ? activeStyle : {}}>
                                <ListItemIcon>
                                    <AddIcon style={activeLink === "loan" ? activeIconColor : iconColor} />
                                </ListItemIcon>
                                <ListItemText primary="Loan" />
                            </ListItemButton>
                        </ListItem>

                        <ListItem component={Link} to="/received-loan" onClick={() => setActiveLink('received-loan')}>
                            <ListItemButton style={activeLink === "received-loan" ? activeStyle : {}}>
                                <ListItemIcon>
                                    <CallReceivedIcon style={activeLink === "received-loan" ? activeIconColor : iconColor} />
                                </ListItemIcon>
                                <ListItemText primary="Received loan" />
                            </ListItemButton>
                        </ListItem> */}
                    </List>
                </nav>
            </Box>
        </div>
    )
}

export default SidePanel;