import React, { useState, useEffect } from 'react'
import './dashboard.css';
import image from './aa.jpg';
import { uploadFileToFirebase } from '../../util/firebaseService';
import { ArrowLeft, ArrowRight, BedroomChild, BookOnline, Login, Logout } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import * as dashboardService from "../../services/dashboardService";
import moment from 'moment';

import { Card, CardContent, Container, Typography } from '@mui/material';

const features = [
  {
    title: 'Financial Intelligence',
    description: `Exciting news! Our upcoming Advanced Financial Insights Hub is on the horizon. Elevate your financial management with advanced calculations, detailed reports, and a comprehensive overview of your business's economic landscape. Stay tuned for a feature that will revolutionize the way you analyze and strategize.`,
  },
  {
    title: 'Role-Based Access Control System',
    description: `Take control of user access through our Role-Based Access Control (RBAC) System. Super Admins can define roles, assign permissions, and tailor system access for enhanced security and efficiency. And that's not all – stay tuned for our upcoming features that will further empower your control over user management, making your system even more secure and user-friendly.`,
  },
  // Add more features as needed
];

const upcomingFeature = {
  title: 'Exciting New Feature Coming Soon!',
  description: 'Stay tuned for our upcoming feature that will revolutionize your experience.',
};

export default function Dashboard() {
  const [file, setFile] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment(new Date).format("yyyy-MM-DD"));
  const [counts, setCounts] = useState({
    categories: 0,
    customers: 0,
    orders: 0,
    products: 0,
  });

  const handleChange = async (event) => {
    setFile(event.target.files[0]);
    uploadFileToFirebase("path/to/file.jpg", file)
      .then(downloadUrl => {
        // Use the download URL here
        console.log("Download URL:", downloadUrl);
      })
      .catch(error => {
        // Handle errors
        console.error("Error uploading file:", error);
      });
  };

  useEffect(() => {
    dashboardService.getCounts()
    .then(res => {
      setCounts(res.data);
      console.log(res.data);
    })
    .catch(err => {
      console.log(err);
    })
  }, []);
  

  const highlightedDays = [selectedDate];
  function ServerDay(props) {
    const { day, outsideCurrentMonth, ...other } = props;
    const isSelected = highlightedDays.includes(day.format('YYYY-MM-DD'));
    const dayStyle = {
      backgroundColor: isSelected || props.selected ? '#1976d2' : 'transparent',
      borderRadius: '50%',
      border: isSelected ? '2px solid #1976d2' : 'none',
      color: isSelected ? 'white' : 'inherit',
      fontWeight: 700
    };

    return (
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        style={dayStyle}
      />
    );
  }



  return (
    <div className='background_container'>
      {/* <div className='background'>
        <img src={image} width="100%" height="100%" />
      </div> */}
      {/* <div style={{ textAlign: "right" }}>
        <button type="button" class="btn btn-outline-secondary">Add-Stock</button>
      </div> */}

      {/* <input type="file" accept="image/*" onChange={handleChange} /> */}
      {/* <button onClick={uploadFileToFirebase}>Upload to Firebase</button>
      <button onClick={deleteFile}>Upload</button> */}

      <div className='dashboard_shapes'>
        <div style={{ width: "45%" }}>
          <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
            <div className='d_sq' style={{ width: "45%", marginBottom: 20 }}>
              <div className='d_box'>
                <div style={{ fontSize: 16 }}>Total Orders</div>
                <div><BookOnline /></div>
              </div>
              <div className='dash_box'>{counts.orders}</div>
            </div>

            <div className='d_sqs' style={{ width: "45%", marginBottom: 20 }}>
              <div className='d_box'>
                <div style={{ fontSize: 16 }}>Total Customers</div>
                <div><BedroomChild /></div>
              </div>
              <div className='dash_box'>{counts.customers}</div>
            </div>

            <div className='d_sqt' style={{ width: "45%", marginBottom: 20 }}>
              <div className='d_box'>
                <div style={{ fontSize: 16 }}>Total Products</div>
                <div><Login /></div>
              </div>
              <div className='dash_box'>{counts.products}</div>
            </div>

            <div className='d_sqf' style={{ width: "45%", marginBottom: 20 }}>
              <div className='d_box'>
                <div style={{ fontSize: 16 }}>Total Categories</div>
                <div><Logout /></div>
              </div>
              <div className='dash_box'>{counts.categories}</div>
            </div>
          </div>

          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                sx={{
                  bgcolor: "#0000000d",
                  width: "100%",
                  height: "100%",
                  borderRadius: 5,
                  '& .MuiDayCalendar-header': {
                    justifyContent: 'space-around',
                  },
                  '& .MuiDayCalendar-weekContainer': {
                    justifyContent: 'space-around',
                  },
                  '& .MuiDayCalendar-weekDayLabel': {
                    fontWeight: 900,
                    fontSize: '0.9rem',
                  },
                  '& .MuiYearCalendar-root': {
                    width: '100%',
                  },
                  '& .MuiButtonBase-root-MuiPickersDay-root ': {
                    fontWeight: 900,
                  }
                }}
                slots={{
                  leftArrowIcon: ArrowLeft,
                  rightArrowIcon: ArrowRight,
                  day: ServerDay,
                }}
                slotProps={{
                  actionBar: {
                    actions: ['today'],
                  },
                }}
              // onChange={(newValue) => setSelectedDate(moment(new Date(newValue)).format("yyyy-MM-DD"))}
              />
            </LocalizationProvider>
          </div>

        </div>
        <div style={{ width: "45%" }}>
          <div color="content">
          <div className="upcoming-section">
        <div className="card upcoming-card">
          <h2 className="title">{upcomingFeature.title}</h2>
          <p className="description">{upcomingFeature.description}</p>
        </div>
      </div>
      <div className="features-section">
        {features.map((feature, index) => (
          <div key={index} className="card">
            <h3 className="title">{feature.title}</h3>
            <p className="description">{feature.description}</p>
          </div>
        ))}
      </div>
          </div>
        </div>
      </div>
    </div>
  )
}
