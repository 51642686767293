import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import Select from 'react-select';
import { Delete, Edit, RemoveRedEyeRounded } from '@mui/icons-material';
import { Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Tabs, Tab, Box } from '@mui/material';

import { ordersActions } from '../../store/ordersSlice';
import { steps, stepColors } from '../../config/config';
import DeliverUpdate from './orderUpdateModal/orderUpdate';
import AddOrEditOrder from './addOrEditOrder/addOrEditOrder';
import * as OrdersService from '../../services/orderService';
import * as ProductService from '../../services/productServices';
import DeleteConfirmationModal from '../shared/deleteConfirmationModal';
import style from './orders.module.css';
import TableSkelton from '../shared/table/tabelSkelton';
import TableEmpty from '../shared/table/tableEmpty';
import TableHeadSection from '../shared/table/tableHead';
import TablePaginationSection from '../shared/table/tablePagination';
import TableBodyRow from '../shared/table/tableBodyRow';
import TableCellAction from '../shared/table/tabelCellAction';
import { errorNotify } from '../../config/notify';
import PrimaryButton from '../shared/addButton';
import { productActions } from '../../store/productSlice';
import { refreshCustomers, refreshProducts } from '../refresh';

const Orders = () => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders.orders);
  const count = useSelector((state) => state.orders.count);
  const isRefreshItems = useSelector((state) => state.orders.isRefreshItems);
  const products = useSelector((state) => state.product.products);
  const customers = useSelector((state) => state.customer.customerList);
  
  const isLoading = useSelector((state) => state.orders.isLoading);
  const isOpenModal = useSelector((state) => state.orders.isOpenModal);
  const isDeliverModalOpen = useSelector((state) => state.orders.isDeliverModalOpen);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [product, setProduct] = useState("all");
  const [customer, setCustomer] = useState("all");
  const [orderId, setOrderId] = useState("");
  const [modifiedCustomersArray, setModifiedCustomersArray] = useState([]);
  const [modifiedProductsArray, setModifiedProductsArray] = useState([]);

  const [modalTitle, setModalTitle] = useState("");
  const [modalAction, setModalAction] = useState("");
  const [status, setStatus] = useState("all");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const tableTitles =["Customer Name", "Product", "Category", "Quantity", "Color", "Size", "Status", "Create At", "Action"];

  useEffect(() => {
    getOrder()
  }, [status, product, customer, page, rowsPerPage]);

  useEffect(() => {
    if(isRefreshItems) {
      getOrder();
    }
  }, [isRefreshItems]);

  useEffect(() => {
    refreshProducts();
    refreshCustomers();
  }, []);

  const getOrder = () => {
    const params = {
      page,
      pageSize: rowsPerPage
    };
    if(status !== "all") {
      params.status = status;
    }
    if(customer.value !== "all") {
      params.customer = customer.value;
    }
    if(product.value !== "all") {
      params.product = product.value;
    }
    
    dispatch(ordersActions.loadingHandler(true));
    dispatch(OrdersService.getOrders(params));
  }

  useEffect(() => {
    if (customers?.length > 0) {
      const newCustomerArray = [{ value: "all", label: "All" }];
      customers.forEach(customer => {
        newCustomerArray.push({ value: customer._id, label: customer.name });
      });
      setModifiedCustomersArray(newCustomerArray);
    }
  }, [customers]);

  useEffect(() => {
    if (products.length > 0) {
      const newProductsArray = [{ value: "all", label: "All" }];
      products.forEach(product => {
        newProductsArray.push({ value: product._id, label: product.name });
      });
      setModifiedProductsArray(newProductsArray);
    }
  }, [products]);

  const deleteOrder = (id) => {
    setIsDeleteModalOpen(true);
    setOrderId(id);
  }

  const createOrder = () => {
    dispatch(ordersActions.modalHandler(true));
    setModalTitle("Create New Order");
    setModalAction("create");
    setOrderId(undefined);
  }

  const editOrder = (p) => {
    setModalTitle("Edit Order");
    setModalAction("edit");
    dispatch(ordersActions.modalHandler(true));
    setOrderId(p._id);
  }

  const viewOrder = (p) => {
    console.log("109");
    setModalTitle("Order Details");
    setModalAction("view");
    dispatch(ordersActions.modalHandler(true));
    setOrderId(p._id);
  }

  const deleteModalHandler = (modalAction, reason) => {
    console.log(modalAction);
    if(modalAction === "confirm") {
      dispatch(OrdersService.deleteOrder(orderId, reason));
    }
    setIsDeleteModalOpen(false);
    setOrderId("");
  }

  return (
    <div className={style.container}>
      <div className={style.header_section}>
        <Box sx={{ bgcolor: '#0000000d' }} style={{ width: "40%" }}>
          <Tabs
            value={status}
            onChange={(e, status) => setStatus(status)}
            textColor="primary"
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab
              value="all"
              label="All"
            />
            {steps.map((step, index) =>
              <Tab
                sx={{ color: stepColors[index], fontWeight: 600, fontSize: 12 }}
                value={step}
                label={step}
              />
            )}
          </Tabs>

        </Box>

        <div style={{width: "20%"}}>
          <Select
            options={modifiedProductsArray}
            isSearchable={true}
            placeholder="Search by product"
            value={product}
            onChange={(product) => setProduct(product)}
          />
        </div>

        <div style={{width: "20%"}}>
          <Select
            options={modifiedCustomersArray}
            isSearchable={true}
            placeholder="Search by customer"
            value={customer}
            onChange={(customer) => setCustomer(customer)}
          />
        </div>

        <div>
          <PrimaryButton name="Create Order" module="Orders" onClick={createOrder} />
        </div>
      </div>

      <div class="alert alert-primary my-2" role="alert">
        You can manage customer orders here and update delivery status.
      </div>

      {isOpenModal &&
        <div className={style.product_form}>
          <AddOrEditOrder
            title={modalTitle}
            modalOpen={isOpenModal}
            action={modalAction}
            orderId={orderId} />
        </div>
      }
      {isDeliverModalOpen && <DeliverUpdate open={isDeliverModalOpen} orderId={orderId} />}
      {isDeleteModalOpen && <DeleteConfirmationModal isDeleteModalOpen={isDeleteModalOpen} deleteModalHandler={deleteModalHandler} />}

      <div className={style.table}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 350 }} aria-label="simple table">
            <TableHeadSection titles={tableTitles} />

            {!isLoading && orders.length > 0 && <TableBody>
              {orders.map((order) => (
                <TableBodyRow>
                  <TableCell component="th" scope="row">{order?.customer?.name}</TableCell>
                  <TableCell component="th" scope="row">{order?.product?.name}</TableCell>
                  <TableCell component="th" scope="row">{order?.product?.category?.name}</TableCell>
                  {/* <TableCell component="th" scope="row">{order?.price}</TableCell> */}
                  <TableCell component="th" scope="row">{order?.quantity}</TableCell>
                  <TableCell component="th" scope="row">{order?.color}</TableCell>
                  <TableCell component="th" scope="row">{order?.size}</TableCell>
                  <TableCell component="th" scope="row">
                    <div className='d-flex align-items-center justify-content-between'>
                      <div style={{ color: stepColors[steps.indexOf(order?.status)], fontWeight: 600, fontSize: 12 }}>
                        {order?.status}
                      </div>
                      {order?.status !== steps[steps.length - 1] && <div>
                        <button className="btn btn-primary" style={{ fontSize: 10, padding: "5px 10px" }} onClick={() => {
                          dispatch(ordersActions.deliverModalHandler(true));
                          setOrderId(order._id);
                        }}>Update</button>
                      </div>}
                    </div>
                  </TableCell>
                  <TableCell component="th" scope="row">{moment(order?.createdAt).format("yyyy-MM-DD HH:MM a")}</TableCell>
                  <TableCellAction module="Orders" viewItem={() => viewOrder(order)} editItem={() => editOrder(order)} deleteItem={() => deleteOrder(order._id)} />
                </TableBodyRow>
              ))}
            </TableBody>}

            {!isLoading && !orders.length && <TableEmpty />}

            {isLoading && <TableSkelton row={4} column={9} />}
          </Table>
        </TableContainer>
        <TablePaginationSection count={count} page={page} rowsPerPage={rowsPerPage} setPage={setPage} setRowsPerPage={setRowsPerPage} />
      </div>
    </div >
  );
};

export default Orders;
