import { TableRow } from "@mui/material";


const TableBodyRow = ({children}) => {
    return (
        <TableRow
            sx={{
                '&:last-child td, &:last-child th': { border: 0 }, '&:hover': {
                    background: '#f2f2f2',
                },
            }}
        >{children}</TableRow>
    );
};

export default TableBodyRow;