import axios from "axios";
import { loanActions } from "../store/loanSlice"
import { API_HOST } from '../config/config';

export const getLoan = () => (dispatch) => {
    axios({
        method: 'get',
        url: API_HOST + '/loans',
    }).then(res => {
        console.log(res.data.data)
        dispatch(loanActions.getLoan(res.data.data))
        // console.log(res);
    })
};

export const addreceive = (name, amount, dateofReceive) => (dispatch) => {
    axios({
        method: 'post',
        url: API_HOST + `/loan`,
        data: {
            name: name,
            amount: amount,
            date: dateofReceive,
            type: 1
        }
    }).then(res => {
        dispatch(getLoan());
    });
};

export const deletereceiver = (ReceivedLoan) => (dispatch) => {
    axios({
        method: 'delete',
        url: API_HOST + `/loan/${ReceivedLoan._id}`,
    }).then(res => {
        dispatch(getLoan());
    });
}


export const editreceive = (name, amount, dateofReceive, id) => (dispatch) => {
    axios({
        method: 'put',
        url: `https://shop-b6ad.onrender.com/loan/${id}`,
        data: {
            name: name,
            amount: amount,
            date: dateofReceive,
            type: 1
        }
    }).then(res => {
        dispatch(getLoan());
    });
}