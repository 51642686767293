import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';

import { Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import * as staffService from '../../services/staffServices';
import AddOrEditStaff from './addOrEditStaff/addOrEditStaff';
import style from './staff.module.css';
import TableCellAction from '../shared/table/tabelCellAction';
import TableHeadSection from '../shared/table/tableHead';
import moment from 'moment';
import { staffActions } from '../../store/staffSlice';
import TableEmpty from '../shared/table/tableEmpty';
import TableSkelton from '../shared/table/tabelSkelton';
import DeleteConfirmationModal from '../shared/deleteConfirmationModal';
import TablePaginationSection from '../shared/table/tablePagination';
import TableBodyRow from '../shared/table/tableBodyRow';
import { errorNotify } from '../../config/notify';
import PrimaryButton from '../shared/addButton';

const Staff = () => {
  const dispatch = useDispatch();
  const staff = useSelector((state) => state.staff.staff);
  const isRefreshItems = useSelector((state) => state.staff.isRefreshItems);

  const count = useSelector((state) => state.staff.count);
  const isLoading = useSelector((state) => state.stock.isLoading);
  const isOpenModal = useSelector((state) => state.stock.isOpenModal);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [modalTitle, setModalTitle] = useState("");
  const [modalAction, setModalAction] = useState("");
  const [staffId, setStaffId] = useState(undefined);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const tableTitles = ["Name", "Email", "Telephone", "Role", "Status", "Created At", "updated At", "Action"];

  useEffect(() => {
    getStaff();
  }, [page, rowsPerPage]);

  useEffect(() => {
    if(isRefreshItems) {
      getStaff();
    }
  }, [isRefreshItems]);

  const getStaff = () => {
    const params = {
      page,
      pageSize: rowsPerPage
    };
    dispatch(staffActions.loadingHandler(true));
    dispatch(staffService.getStaffList(params));
  }

  const deleteHandler = (id) => {
    setIsDeleteModalOpen(true);
    setStaffId(id);
  }

  const createStaff = () => {
    dispatch(staffActions.modalHandler(true));
    setModalTitle("Create Staff");
    setModalAction("create");
    setStaffId(undefined);
  }

  const editStaff = (staff) => {
    dispatch(staffActions.modalHandler(true));
    setModalTitle("Update Staff");
    setModalAction("edit");
    setStaffId(staff._id);
  }

  const viewStaff = (p) => {
    dispatch(staffActions.modalHandler(true));
    setModalTitle("Staff Details");
    setModalAction("view");
    // setModalOpen(true);
    setStaffId(p._id);
  }

  const deleteModalHandler = (modalAction, reason) => {
    console.log(modalAction);
    if (modalAction === "confirm") {
      dispatch(staffService.deleteStaff(staffId, reason));
    }
    setIsDeleteModalOpen(false);
    setStaffId("");
  }

  const statusHandler = (value, id) => {
    dispatch(staffService.updateStatus(id, value));
  }

  return (
    <div className={style.container}>
      <PrimaryButton name="Create Staff" module="Staff" onClick={createStaff} />

      {isOpenModal &&
        <div className={style.product_form}>
          <AddOrEditStaff
            title={modalTitle}
            modalOpen={isOpenModal}
            action={modalAction}
            staffId={staffId} />
        </div>
      }

      {isDeleteModalOpen && <DeleteConfirmationModal isDeleteModalOpen={isDeleteModalOpen} deleteModalHandler={deleteModalHandler} />}

      <div className="alert alert-primary my-2" role="alert">
        Here, you can create a new user / new staff to access this application with the initial password 'adminuser.' Once created, user can change the user's password. Currently, only this user has access to the system. 
        In the future, access will be role-based, granting specific permissions based on different user roles.
      </div>

      <div className={style.table}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 350 }} aria-label="simple table">
            <TableHeadSection titles={tableTitles} />

            {!isLoading && staff.length > 0 && <TableBody>
              {staff.map((st) => (
                <TableBodyRow>
                  <TableCell component="th" scope="row">{st?.name}</TableCell>
                  <TableCell component="th" scope="row">{st?.email}</TableCell>
                  <TableCell component="th" scope="row">{st?.telephone}</TableCell>
                  <TableCell component="th" scope="row">{st?.role?.name}</TableCell>
                  <TableCell ><Switch checked={st?.status} onChange={(e) => statusHandler(e.target.checked, st._id)} /></TableCell>
                  <TableCell component="th" scope="row">{moment(st?.createdAt).format("yyyy-MM-DD HH:MM a")}</TableCell>
                  <TableCell component="th" scope="row">{moment(st?.updatedAt).format("yyyy-MM-DD HH:MM a")}</TableCell>
                  <TableCellAction module="Staff" viewItem={() => viewStaff(st)} editItem={() => editStaff(st)} deleteItem={() => deleteHandler(st._id)} />
                </TableBodyRow>
              ))}
            </TableBody>}

            {!isLoading && !staff.length && <TableEmpty />}

            {isLoading && <TableSkelton row={4} column={8} />}

          </Table>
        </TableContainer>
        <TablePaginationSection count={count} page={page} rowsPerPage={rowsPerPage} setPage={setPage} setRowsPerPage={setRowsPerPage} />
      </div>
    </div >
  );
};

export default Staff;
