import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    orders: [],
    count: 0,
    isLoading: true,
    isOpenModal: false,
    isDeliverModalOpen: false,
    isRefreshItems: false
};

const ordersSlice = createSlice({
    name: "event",
    initialState: initialState,
    reducers: {
        getOrders: (state, action) => {
            const orders = action.payload;
            // console.log(orders)
            state.orders = orders.orders ?? [];
            state.count = orders.count;
            state.isRefreshItems = false;
        },
        loadingHandler: (state, action) => {
            state.isLoading = action.payload;
        },
        modalHandler: (state, action) => {
            state.isOpenModal = action.payload;
        },
        deliverModalHandler: (state, action) => {
            state.isDeliverModalOpen = action.payload;
        },
        refreshItems: (state, action) => {
            state.isRefreshItems = true;
        },
    },
});

export const ordersActions = ordersSlice.actions;

export default ordersSlice;