import { deleteFile } from "./firebaseService";

export const removeImage = (removeImageArray) => {
    for(const url of removeImageArray) {
        deleteFile(url)
            .then(() => {
                console.log("Successfully deleted:");
            })
            .catch((error) => {
                console.error("Error deleting file:", error);
            });
    }
}