import React, { useState, useEffect } from 'react'
import style from './systemRole.module.css';
import { BedroomChild, BookOnline, Login, Logout } from '@mui/icons-material';
import * as financeService from "../../services/financeService";
import * as systemRolesService from "../../services/systemRolesService";
import moment from 'moment';

import { Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Circles } from 'react-loader-spinner';

import { uploadFileToFirebase } from '../../util/firebaseService';
import * as categoryService from '../../services/categoryService';
import TableSkelton from '../shared/table/tabelSkelton';
import TableHeadSection from '../shared/table/tableHead';
import TableEmpty from '../shared/table/tableEmpty';
import TableBodyRow from '../shared/table/tableBodyRow';
import TableCellAction from '../shared/table/tabelCellAction';
import TablePaginationSection from '../shared/table/tablePagination';
import { categoryActions } from '../../store/categorySlice';
import DeleteConfirmationModal from '../shared/deleteConfirmationModal';
import { errorNotify } from '../../config/notify';

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { systemRolesActions } from '../../store/systemRoles';
import { useDispatch, useSelector } from 'react-redux';

export default function SystemRole() {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const systemRolesAccess = useSelector((state) => state.systemRoles.systemRolesAccess);
  const systemRoles = useSelector((state) => state.systemRoles.systemRoles);
  const isLoading = useSelector((state) => state.systemRoles.isLoading);
  const loadingHandlerSystemRole = useSelector((state) => state.systemRoles.loadingHandlerSystemRole);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [roleId, setRoleId] = useState("");

  const tableTitles = ["Module Name", "Role", "View", "Create", "Edit", "Delete"];
  const tableTitlesRoles = ["Role Name", "Action"];


  useEffect(() => {
    dispatch(systemRolesActions.loadingHandler(true));
    dispatch(systemRolesService.getSystemRoles());
  }, []);

  useEffect(() => {
    if(roleId) {
      dispatch(systemRolesActions.loadingHandler(true));
      dispatch(systemRolesService.getSystemRolesAccess(roleId));
    }
  }, [roleId]);

  console.log(systemRoles);

  useEffect(() => {
   setRoleId(systemRoles[0]?._id);
  }, [systemRoles])
  

  const editRoleHandler = (value, systemRole, type) => {

    const data = {
      role: roleId,
      module: systemRole._id,
      type,
      value
    }
    dispatch(systemRolesService.updateSystemRoleAccess(data));
  };

  const createRole = () => {
    dispatch(systemRolesActions.loadingHandlerSystemRole(true));
    dispatch(systemRolesService.createSystemRole(name));
    setName("");
  };

  const editRole = () => {
    dispatch(systemRolesActions.loadingHandlerSystemRole(true));
    dispatch(systemRolesService.updateSystemRole(roleId, name));
    setIsEdit(false);
    setName("");
  };

  const deleteRole = (role) => {
    setIsDeleteModalOpen(true);
    setRoleId(role._id);
  };

  const deleteModalHandler = (modalAction, reason) => {
    if(modalAction === "confirm") {
      dispatch(systemRolesActions.loadingHandlerSystemRole(true));
      dispatch(systemRolesService.deleteSystemRole(roleId, reason));
    }
    setIsDeleteModalOpen(false);
    roleId("");
  }

  const editHandler = (systemRole) => {
    setIsEdit(true);
    setName(systemRole.name);
    setRoleId(systemRole._id);
  }


  return (
    <div className={style.confiner}>
      {isDeleteModalOpen && <DeleteConfirmationModal isDeleteModalOpen={isDeleteModalOpen} deleteModalHandler={deleteModalHandler} />}
      <div className={style.system_role_container}>
        <div style={{width: "30%"}}>
          <div className={style.system_role_container} >
            <div className="form-group" style={{ marginRight: 10 }}>
              <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Role Name" value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            {!isEdit && <button style={{ height: "40px", fontSize: 14 }} type="button" className="btn btn-primary" onClick={createRole}>Create</button>}
            {isEdit && <button style={{ height: "40px", fontSize: 14, marginRight: 10 }} type="button" className="btn btn-primary" onClick={() => editRole()}>Update</button>}
          </div>
          <div className="mt-2">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 200 }} aria-label="simple table">
                  <TableHeadSection titles={tableTitlesRoles} />
                  {!loadingHandlerSystemRole && systemRoles.length > 0 && <TableBody>
                    {systemRoles.map((systemRole) => (
                      <TableBodyRow sx={{height: 10, padding: 5}}>
                        <TableCell onClick={() => setRoleId(systemRole._id)} sx={{ display: "flex", justifyContent: "space-around", alignItems: "center", cursor: "pointer", ...(systemRole._id === roleId ? { backgroundColor: "#0000000d", color: "blue" } : {}) }}>
                          {systemRole.name}
                          <span onClick={() => setRoleId(systemRole._id)}><button className="btn btn-success btn-sm" style={{fontSize: 10}}>View Permissions</button></span>
                          </TableCell>
                        <TableCellAction module="System Roles" editItem={() => editHandler(systemRole)} deleteItem={() => deleteRole(systemRole)} />
                      </TableBodyRow>
                    ))}
                  </TableBody>}
                  {/* {!isLoading && !categories.length && <TableEmpty />} */}
                  {/* {isLoading && <TableSkelton row={4} column={7} />} */}
              {!loadingHandlerSystemRole && !systemRoles.length && <TableEmpty />}
              {loadingHandlerSystemRole && <TableSkelton row={2} column={2} />}
                </Table>
              </TableContainer>
          </div>
        </div>

        <div className={style.table}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 350 }} aria-label="simple table">
              <TableHeadSection titles={tableTitles} />
              {!isLoading && systemRolesAccess.length > 0 && roleId && <TableBody>
                {systemRolesAccess.map((systemRole) => (
                  <TableBodyRow>
                    <TableCell>{systemRole.moduleName}</TableCell>
                    <TableCell>{systemRoles.find(el => el._id === roleId)?.name}</TableCell>
                    <TableCell>{<Switch checked={systemRole.view} onChange={(e) => editRoleHandler(e.target.checked, systemRole, "view")} />}</TableCell>
                    <TableCell>{<Switch checked={systemRole.create} onChange={(e) => editRoleHandler(e.target.checked, systemRole, "create")} />}</TableCell>
                    <TableCell>{<Switch checked={systemRole.edit} onChange={(e) => editRoleHandler(e.target.checked, systemRole, "edit")} />}</TableCell>
                    <TableCell>{<Switch checked={systemRole.delete} onChange={(e) => editRoleHandler(e.target.checked, systemRole, "delete")} />}</TableCell>
                  </TableBodyRow>
                ))}
              </TableBody>}
                {!isLoading && !systemRolesAccess.length && <TableEmpty />}
                {isLoading && <TableSkelton row={4} column={6} />}
            </Table>
          </TableContainer>
        </div>

      </div>
    </div>
  )
}
