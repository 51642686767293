import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    count: 0,
    systemRolesAccess: [],
    systemRoles: [],
    isLoading: true,
    loadingHandlerSystemRole: true,
    isOpenModal: false,
    isRefreshItems: false
};

const systemRolesSlice = createSlice({
    name: "event",
    initialState: initialState,
    reducers: {
        getSystemRolesAccess: (state, action) => {
            const systemRolesAccess = action.payload;
            state.systemRolesAccess = systemRolesAccess;
        },
        getSystemRoles: (state, action) => {
            const systemRoles = action.payload;
            state.systemRoles = systemRoles;
        },
        loadingHandler: (state, action) => {
            state.isLoading = action.payload;
        },
        loadingHandlerSystemRole: (state, action) => {
            state.loadingHandlerSystemRole = action.payload;
        }
    },
});

export const systemRolesActions = systemRolesSlice.actions;

export default systemRolesSlice;