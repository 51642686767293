import axios from 'axios';
import { API_HOST } from '../config/config';
import { staffActions } from '../store/staffSlice';
import axiosInstance from '../config/axiosInstance';
import { successNotify, errorNotify } from '../config/notify';

export const getStaffList = (params) => (dispatch) => {
    axiosInstance({
        method: 'get',
        url: API_HOST + '/user/staff',
        params
    }).then(res => {
        dispatch(staffActions.loadingHandler(false));
        dispatch(staffActions.getStaff(res.data.data));
    }).catch(err => {
        errorNotify(err.response?.data?.message || err.message);
        dispatch(staffActions.loadingHandler(false));
    });
};

export const createStaff = (staffJson) => (dispatch) => {
    axiosInstance({
        method: 'post',
        url: API_HOST + '/user/staff',
        data: staffJson
    }).then(res => {
        successNotify("Successfully created staff member.");
        dispatch(staffActions.modalHandler(false));
        dispatch(staffActions.loadingHandler(true));
        dispatch(staffActions.refreshItems());
    }).catch(err => {
        console.log(err.response);
        errorNotify(err.response?.data?.message || err.message);
        dispatch(staffActions.loadingHandler(false));
    });
};

// export const prodeleteHandler = (p) => (dispatch) => {
//     console.log()
//     axios({
//         method: 'delete',
//         url: API_HOST + `/user/staff/${p._id}`,
//         // data: {
//         //     name: product,
//         //     category: category
//         // }
//     }).then(res => {
//         dispatch(getProduct());
//     });
// }

export const updateStaff = (staffJson, id) => (dispatch) => {
    axiosInstance({
        method: 'put',
        url: API_HOST + `/user/staff/${id}`,
        data: staffJson
    }).then(res => {
        successNotify("Successfully updated staff member details.");
        dispatch(staffActions.modalHandler(false));
        dispatch(staffActions.loadingHandler(true));
        dispatch(staffActions.refreshItems());
    }).catch(err => {
        console.log(err.response);
        errorNotify(err.response?.data?.message || err.message);
        dispatch(staffActions.loadingHandler(false));
    });
}

export const deleteStaff = (id, reason) => (dispatch) => {
    axiosInstance({
        method: 'delete',
        url: API_HOST + `/user/staff/${id}`,
        data: {
            reason,
        }
    }).then(res => {
        successNotify("Successfully deleted staff member.");
        // dispatch(staffActions.modalHandler(false));
        dispatch(staffActions.loadingHandler(true));
        dispatch(staffActions.refreshItems());
    }).catch(err => {
        console.log(err.response);
        errorNotify(err.response?.data?.message || err.message);
        dispatch(staffActions.loadingHandler(false));
    });
}

export const updateStatus = (id, status) => (dispatch) => {
    axiosInstance({
        method: 'put',
        url: API_HOST + `/user/staff/update-status/${id}`,
        data: {
            status
        }
    }).then(() => {
        if(status) {
            successNotify("Access successfully activated.");
        } else {
            successNotify("Access denied successfully for the user.");
        }
        dispatch(staffActions.refreshItems());
    }).catch(err => {
        console.log(err.response);
        errorNotify(err.response?.data?.message || err.message);
    });
};