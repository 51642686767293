import axios from 'axios';
import { categoryActions } from "../store/categorySlice";
import { API_HOST } from '../config/config';
import { successNotify, errorNotify } from '../config/notify';
import axiosInstance from '../config/axiosInstance';
import { removeImage } from '../util/removeImages';

export const getCategories = (params) => (dispatch) => {
    axiosInstance({
        method: 'get',
        url: API_HOST + '/categories',
        params
    }).then(res => {
        dispatch(categoryActions.loadingHandler(false));
        dispatch(categoryActions.getCategories(res.data.data));
    }).catch(err => {
        console.log(err.response);
        errorNotify(err.response?.data?.message || err.message);
        dispatch(categoryActions.loadingHandler(false));
    });
};

export const createCategory = (name, image) => (dispatch) => {
    axiosInstance({
        method: 'post',
        url: API_HOST + '/categories',
        data: {
            name,
            image
        }
    }).then(() => {
        successNotify("Successfully created category.");
        dispatch(categoryActions.modalHandler(false));
        dispatch(categoryActions.loadingHandler(true));
        dispatch(categoryActions.refreshCategories());
    }).catch(err => {
        console.log(err.response);
        errorNotify(err.response?.data?.message || err.message);
        dispatch(categoryActions.loadingHandler(false));
    });
};

export const deleteCategory = (id, reason, previousImage) => (dispatch) => {
    axiosInstance({
        method: 'delete',
        url: API_HOST + `/categories/${id}`,
        data: {
            reason,
        }
    }).then(() => {
        successNotify("Successfully deleted category.");
        dispatch(categoryActions.modalHandler(false));
        dispatch(categoryActions.loadingHandler(true));
        dispatch(categoryActions.refreshCategories());
        if(previousImage) {
            removeImage([previousImage]);
        }
    }).catch(err => {
        console.log(err.response);
        errorNotify(err.response?.data?.message || err.message);
        dispatch(categoryActions.loadingHandler(false));
    });
};

export const updateCategory = (name, image, id, previousImage) => (dispatch) => {
    axiosInstance({
        method: 'put',
        url: API_HOST + `/categories/${id}`,
        data: {
            name,
            image
        }
    }).then(() => {
        successNotify("Successfully updated category.");
        dispatch(categoryActions.modalHandler(false));
        dispatch(categoryActions.loadingHandler(true));
        dispatch(categoryActions.refreshCategories());
        if(previousImage) {
            removeImage([previousImage]);
        }
    }).catch(err => {
        console.log(err.response);
        errorNotify(err.response?.data?.message || err.message);
        dispatch(categoryActions.loadingHandler(false));
    });
};

export const updateVisible = (id, isVisible) => (dispatch) => {
    axiosInstance({
        method: 'put',
        url: API_HOST + `/categories/${id}/update-visible`,
        data: {
            isVisible
        }
    }).then(() => {
        if(isVisible) {
            successNotify("Successfully updated to be visible to the customer.");
        } else {
            successNotify("Successfully updated to be invisible to the customer.");
        }
        dispatch(categoryActions.refreshCategories());
    }).catch(err => {
        console.log(err.response);
        errorNotify(err.response?.data?.message || err.message);
        dispatch(categoryActions.loadingHandler(false));
    });
};