import { Skeleton, TableBody, TableCell, TableRow } from "@mui/material";

const TableSkelton = ({ row, column }) => {
    console.log(row)
    return (
        <TableBody>
            {Array.from({ length: row }).map((el) => (
                <TableRow
                    key={el}
                    sx={{
                        '&:last-child td, &:last-child th': { border: 0 }
                    }}
                >{Array.from({ length: column }).map(el => <TableCell sx={{ fontSize: 12 }} align="left"><Skeleton /></TableCell>)}
                </TableRow>
            ))}
        </TableBody>
    )
};

export default TableSkelton;