import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    categories: [],
    isLoading: true,
    count: 0,
    isRefreshCategories: false
};

const categorySlice = createSlice({
    name: "event",
    initialState: initialState,
    reducers: {
        getCategories: (state, action) => {
            const categories = action.payload;
            state.categories = categories.categories;
            state.count = categories.count;
            state.isRefreshCategories = false;
        },
        loadingHandler: (state, action) => {
            state.isLoading = action.payload;
        },
        modalHandler: (state, action) => {
            state.isOpenModal = action.payload;
        },
        refreshCategories: (state, action) => {
            state.isRefreshCategories = true;
        },
    },
});

export const categoryActions = categorySlice.actions;

export default categorySlice;