import React from 'react'
import './add-loan.css';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { adloan, deleteloan, editloan, getAddloan } from '../../services/addloanService';
import { useDispatch, useSelector } from "react-redux";

export default function AddLoan() {


    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [amount, setAmount] = useState("");
    const [dateofIssue, setDateofIssue] = useState("");
    const [dataArray, setDataArray] = useState([]);

    const addloan = useSelector((state) => state.addloan.addloan);

    const [id, setId] = useState("");
    // console.log(id)

    // console.log(dateofIssue);

    const addloanHandler = () => {
        dispatch(adloan(name, amount, dateofIssue));
    };

    const editloanHandler = () => {
        dispatch(editloan(name, amount, dateofIssue, id))
    }

    const editHandler = (loan) => {
        // console.log(loan);
        console.log(loan.name);
        console.log(loan.amount);
        console.log(loan.dateofIssue);

        setName(loan.name);
        setAmount(loan.amount);
        setDateofIssue(loan.dateofIssue);

        setId(loan._id)
    }

    const deleteHandler = (loan) => {
        dispatch(deleteloan(loan));
    }


    useEffect(() => {
        getAddloan();
        dispatch(getAddloan());
    }, [])




    return (
        <div className='ln_contanier'>
            <div className='loan'>
                <div class="form-group">
                    <label for="exampleInputEmail1" style={{ color: "red" }}>Name</label>
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                <div class="form-group">
                    <label for="exampleInputPassword1" style={{ color: "green" }}>Amount</label>
                    <input type="number" class="form-control" id="exampleInputPassword1" placeholder="Amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                </div>
                <div class="form-group">
                    <label for="exampleInputPassword1" style={{ color: "blue" }}>Date of Issue</label>
                    <input type="date" class="form-control" id="exampleInputPassword1" placeholder="Date of Issue" value={dateofIssue} onChange={(e) => setDateofIssue(e.target.value)} />
                </div>
                <button type="button" style={{ width: "100px", height: "40px" }} onClick={addloanHandler} class="btn btn-outline-danger">Submit</button>
                <button type="button" style={{ width: "100px", height: "40px" }} onClick={editloanHandler} class="btn btn-outline-warning">Edit</button>
            </div>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 350 }} aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: "#0000000d", fontWeight: 700 }}>
                            <TableCell sx={{ fontWeight: 700 }}>Name</TableCell>
                            <TableCell sx={{ fontWeight: 700 }}>Amount</TableCell>
                            <TableCell sx={{ fontWeight: 700 }}>Date</TableCell>
                            <TableCell sx={{ fontWeight: 700 }}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {addloan.map((loan) => (
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {loan.name}
                                    {loan.amount}
                                    {loan.date}
                                </TableCell>
                                <TableCell ></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                                <td className='btn-ln'>
                                    <button class="btn" onClick={() => editHandler(loan)}><i class="fa fa-edit"></i></button>
                                    <button class="btn" onClick={() => deleteHandler(loan)}><i class="fa fa-trash"></i></button>
                                </td>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* <div className='ln_tbl'>
                <table class="table table-success table-striped-colums table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Date of Issue</th>
                            <th scope="col">Action </th>
                        </tr>
                    </thead>
                    <tbody>
                        {addloan.map(loan => {
                            return <tr>
                                <td>{loan.name}</td>
                                <td>{loan.amount}</td>
                                <td>{loan.date}</td> <td className='btn-ln'>
                                    <button class="btn" onClick={() => editHandler(loan)}><i class="fa fa-edit"></i></button>
                                    <button class="btn" onClick={() => deleteHandler(loan)}><i class="fa fa-trash"></i></button>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div> */}

        </div >
    )
}
