import axios from 'axios';
import { stockActions } from '../store/stockSlice';
import { API_HOST } from '../config/config';
import { successNotify, errorNotify } from '../config/notify';
import axiosInstance from '../config/axiosInstance';

export const getStocks = (params) => (dispatch) => {
    axiosInstance({
        method: 'get',
        url: API_HOST + '/stocks',
        params
    }).then(res => {
        dispatch(stockActions.loadingHandler(false));
        dispatch(stockActions.getStocks(res.data.data));
    }).catch(err => {
        errorNotify(err.response?.data?.message || err.message);
        dispatch(stockActions.loadingHandler(false));
    });
}

export const createStock = (stockJson) => (dispatch) => {
    axiosInstance({
        method: 'post',
        url: API_HOST + '/stocks',
        data: stockJson
    }).then(res => {
        successNotify("Successfully created stock.");
        dispatch(stockActions.modalHandler(false));
        dispatch(stockActions.loadingHandler(true));
        dispatch(stockActions.refreshItems());
    }).catch(err => {
        console.log(err.response);
        errorNotify(err.response?.data?.message || err.message);
        dispatch(stockActions.loadingHandler(false));
    });
}

export const updateStock = (stockJson, id) => (dispatch) => {
    axiosInstance({
        method: 'put',
        url: API_HOST + `/stocks/${id}`,
        data: stockJson
    }).then(res => {
        successNotify("Successfully updated stock.");
        dispatch(stockActions.modalHandler(false));
        dispatch(stockActions.loadingHandler(true));
        dispatch(stockActions.refreshItems());
    }).catch(err => {
        console.log(err.response);
        errorNotify(err.response?.data?.message || err.message);
        dispatch(stockActions.loadingHandler(false));
    });
}

export const deleteStock = (id, reason) => (dispatch) => {
    axiosInstance({
        method: 'delete',
        url: API_HOST + `/stocks/${id}`,
        data: {
            reason,
        }
    }).then(res => {
        successNotify("Successfully deleted stock.");
        dispatch(stockActions.modalHandler(false));
        dispatch(stockActions.loadingHandler(true));
        dispatch(stockActions.refreshItems());
    }).catch(err => {
        console.log(err.response);
        errorNotify(err.response?.data?.message || err.message);
        dispatch(stockActions.loadingHandler(false));
    });
}