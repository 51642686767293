import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    salesShop: [],
    count: 0,
    isLoading: true,
    isOpenModal: false,
    isRefreshItems: false
};

const salesShopSlice = createSlice({
    name: "event",
    initialState: initialState,
    reducers: {
        getSalesShop: (state, action) => {
            const salesShop = action.payload;
            console.log(salesShop)
            state.salesShop = salesShop.salesShop ?? [];
            state.count = salesShop.count;
            state.isRefreshItems = false;
        },
        loadingHandler: (state, action) => {
            state.isLoading = action.payload;
        },
        modalHandler: (state, action) => {
            state.isOpenModal = action.payload;
        },
        refreshItems: (state, action) => {
            state.isRefreshItems = true;
        },
    },
});

export const salesShopActions = salesShopSlice.actions;

export default salesShopSlice;