import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    staff: [],
    isLoading: true,
    isOpenModal: false,
    count: 0,
    isRefreshItems: false
};

const staffSlice = createSlice({
    name: "event",
    initialState: initialState,
    reducers: {
        getStaff: (state, action) => {
            const staff = action.payload;
            state.staff = staff.staff ?? [];
            state.count = staff.count;
            state.isRefreshItems = false;
        },
        loadingHandler: (state, action) => {
            state.isLoading = action.payload;
        },
        modalHandler: (state, action) => {
            state.isOpenModal = action.payload;
        },
        refreshItems: (state, action) => {
            state.isRefreshItems = true;
        },
    },
});

export const staffActions = staffSlice.actions;

export default staffSlice;