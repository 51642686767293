import { TablePagination } from "@mui/material";


const TablePaginationSection = ({count, page, rowsPerPage, setPage, setRowsPerPage}) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'end', alignItems: "center" }}>
            <TablePagination sx={{ display: 'flex', justifyContent: 'end', alignItems: "center" }}
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, newPage) => setPage(newPage)}
                onRowsPerPageChange={(e) => {
                    setPage(0);
                    setRowsPerPage(+e.target.value);
                }}
            />
        </div>
    )
};

export default TablePaginationSection;