import axios from "axios";
import { authAction } from "../store/authSlice";
import { API_HOST } from '../config/config';
import { errorNotify } from "../config/notify";

export const Login = (email, password, navigate) => (dispatch) => {
    axios({
        method: 'post',
        url: API_HOST + '/auth/login/staff',
        data: {
            email: email.trim().toLowerCase(),
            password: password,
        }
    }).then(res => {
        localStorage.setItem("isLogin", true);
        localStorage.setItem("token", res.data?.data?.token);
        dispatch(authAction.isLogin());
        dispatch(authAction.loginData(res.data?.data));
        navigate("/dashboard");
    }).catch(err => {
        console.log(err.response);
        errorNotify(err.response?.data?.message || err.message);
    });
};
