import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loan: [],
};

const loanSlice = createSlice({
    name: "event",
    initialState: initialState,
    reducers: {
        getLoan: (state, action) => {
            const loan = action.payload;
            console.log(loan)
            state.loan = loan;
        },
        createloan: (state, action) => {

        },
    },
});

export const loanActions = loanSlice.actions;

export default loanSlice;