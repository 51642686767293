// api.js
import axios from 'axios';
import { authAction } from '../store/authSlice';
import store from '../store/store';
import { useNavigate } from 'react-router-dom';
// import { useRouter } from 'next/router';
// import Test from "./test";

const axiosInstance = axios.create();
// const navigate = useNavigate();

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Retrieve token from local storage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle 401 Unauthorized error
      localStorage.removeItem('isLogin');
      localStorage.removeItem('token');
      handleUnauthorizedError();
      
    }
    return Promise.reject(error);
  }
);

const handleUnauthorizedError = () => {
  // navigate.push('/login');
 store.dispatch(authAction.isLogout());
};

export default axiosInstance;
