import { TableBody, TableCell, TableRow } from "@mui/material";


const TableEmpty = () => {
    return (
        <TableBody>
            <TableRow
                sx={{
                    '&:last-child td, &:last-child th': { border: 0 }, '&:hover': {
                        background: '#f2f2f2',
                    },
                }}
            >
                <TableCell component="th" scope="row">No Content Found</TableCell>
            </TableRow>
        </TableBody>
    );
};

export default TableEmpty;