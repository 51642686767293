import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    count: 0,
    customerList: [],
    isLoading: true,
    isOpenModal: false,
    isRefreshItems: false
};

const customerSlice = createSlice({
    name: "customer",
    initialState: initialState,
    reducers: {
        getCustomer: (state, action) => {
            const customer = action.payload;
            state.customerList = customer.customers;
            state.count = customer.count;
            state.isRefreshItems = false;
        },
        loadingHandler: (state, action) => {
            state.isLoading = action.payload;
        },
        modalHandler: (state, action) => {
            state.isOpenModal = action.payload;
        },
        refreshItems: (state, action) => {
            state.isRefreshItems = true;
        },
    },
});

export const customerActions = customerSlice.actions;

export default customerSlice;