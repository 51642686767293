import axios from 'axios';
import { API_HOST } from '../config/config';
import axiosInstance from '../config/axiosInstance';
import { successNotify, errorNotify } from '../config/notify';
import { customerActions } from '../store/customerSlice';

export const getCustomerList = (params) => (dispatch) => {
    axiosInstance({
        method: 'get',
        url: API_HOST + '/user/customer',
        params
    }).then(res => {
        dispatch(customerActions.loadingHandler(false));
        dispatch(customerActions.getCustomer(res.data.data));
    }).catch(err => {
        errorNotify(err.response?.data?.message || err.message);
        dispatch(customerActions.loadingHandler(false));
    });
}

export const createCustomer = (customerJson) => (dispatch) => {
    axiosInstance({
        method: 'post',
        url: API_HOST + '/user/customer',
        data: customerJson
    }).then(res => {
        successNotify("Successfully created customer.");
        dispatch(customerActions.modalHandler(false));
        dispatch(customerActions.loadingHandler(true));
        dispatch(customerActions.refreshItems());
    }).catch(err => {
        console.log(err.response);
        errorNotify(err.response?.data?.message || err.message);
        dispatch(customerActions.loadingHandler(false));
    });

}

export const updateCustomer = (customerJson, id) => (dispatch) => {
    axiosInstance({
        method: 'put',
        url: API_HOST + `/user/customer/${id}`,
        data: customerJson
    }).then(res => {
        successNotify("Successfully updated customer details.");
        dispatch(customerActions.modalHandler(false));
        dispatch(customerActions.loadingHandler(true));
        dispatch(customerActions.refreshItems());
    }).catch(err => {
        console.log(err.response);
        errorNotify(err.response?.data?.message || err.message);
        dispatch(customerActions.loadingHandler(false));
    });
}

export const deleteStaff = (id, reason) => (dispatch) => {
    axiosInstance({
        method: 'delete',
        url: API_HOST + `/user/staff/${id}`,
        data: {
            reason,
        }
    }).then(res => {
        successNotify("Successfully deleted customer.");
        dispatch(customerActions.loadingHandler(true));
        dispatch(customerActions.refreshItems());
    }).catch(err => {
        console.log(err.response);
        errorNotify(err.response?.data?.message || err.message);
        dispatch(customerActions.loadingHandler(false));
    });
}

export const updateStatus = (id, status) => (dispatch) => {
    axiosInstance({
        method: 'put',
        url: API_HOST + `/user/staff/update-status/${id}`,
        data: {
            status
        }
    }).then(() => {
        if(status) {
            successNotify("Successfully access activated  for the customer.");
        } else {
            successNotify("Successfully access denied  for the customer.");
        }
        dispatch(customerActions.refreshItems());
    }).catch(err => {
        console.log(err.response);
        errorNotify(err.response?.data?.message || err.message);
    });
};

export const deleteCustomer = (id, reason) => (dispatch) => {
    axiosInstance({
        method: 'delete',
        url: API_HOST + `/user/customer/${id}`,
        data: {
            reason,
        }
    }).then(res => {
        successNotify("Successfully deleted customer.");
        // dispatch(staffActions.modalHandler(false));
        dispatch(customerActions.loadingHandler(true));
        dispatch(customerActions.refreshItems());
    }).catch(err => {
        console.log(err.response);
        errorNotify(err.response?.data?.message || err.message);
        dispatch(customerActions.loadingHandler(false));
    });
}