import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';

import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

import * as ordersService from "../../../services/orderService";
import * as customerService from '../../../services/customerService';
import * as productService from '../../../services/productServices';
import { steps, stepColors, symbol } from '../../../config/config';
import { warningNotify } from '../../../config/notify';
import { ordersActions } from '../../../store/ordersSlice';
import style from "./addOrEditOrder.module.css";
import { productCalculation } from '../../../util/calculation';
import { refreshCustomers, refreshProducts } from '../../refresh';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function AddOrEditProduct({ title, modalOpen, action, orderId }) {
    const dispatch = useDispatch();
    const products = useSelector((state) => state.product.products);
    const orders = useSelector((state) => state.orders.orders);
    const customers = useSelector((state) => state.customer.customerList);

    const [price, setPrice] = useState();
    const [quantity, setQuantity] = useState(0);
    const [product, setProduct] = useState("");
    const [selectedProduct, setSelectedProduct] = useState();
    const [amount, setAmount] = useState(0);
    const [customer, setCustomer] = useState("");
    const [customerAddress, setCustomerAddress] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState("");
    const [isCalculateInService, setIsCalculateInService] = useState(true);
    const [orderProcess, setOrderProcess] = useState([]);
    const [modifiedCustomersArray, setModifiedCustomersArray] = useState([]);
    const [modifiedProductsArray, setModifiedProductsArray] = useState([]);
    const [remainingQuantity, setRemainingQuantity] = useState(0);
    const [receiversName, setReceiversName] = useState("");
    const [receiversTelephone, setReceiversTelephone] = useState("");

    const [dealOfTheDayDateValidation, setDealOfTheDayDateValidation] = useState(false);
    const [isEnabledDiscount, setIsEnabledDiscount] = useState(false);
    const [discountObject, setDiscountObject] = useState({});
    const [selectedOrder, setSelectedOrder] = useState({});

    const [colors, setColors] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [size, setSize] = useState("");
    const [color, setColor] = useState("");
    const [discount, setDiscount] = useState(0);
    const [items, setItems] = useState([]);

    useEffect(() => {
        dispatch(customerService.getCustomerList());
        dispatch(productService.getProducts());
    }, []);

    useEffect(() => {
        refreshProducts();
        refreshCustomers();
      }, []);

    useEffect(() => {
        if (customers.length > 0) {
            const newCustomerArray = [];
            customers.forEach(customer => {
                newCustomerArray.push({ value: customer._id, label: customer.name });
            });
            setModifiedCustomersArray(newCustomerArray);
        }
    }, [customers]);

    useEffect(() => {
        if (products.length > 0) {
            const newProductsArray = [];
            products.forEach(product => {
                newProductsArray.push({ value: product._id, label: product.name });
            });
            setModifiedProductsArray(newProductsArray);
        }
    }, [products]);

    useEffect(() => {
        if (orderId) {
            const order = orders.find(el => el._id === orderId);
            setSelectedOrder(order);
            console.log(order);
            setProduct({ value: order.product._id, label: order.product.name });
            setAmount(order.amount);
            setColor(order.color);
            setSize(order.size);
            setQuantity(order.quantity);
            setReceiversName(order.receiversName);
            setReceiversTelephone(order.receiversTelephone);
            setDiscount(order.discountAmount);
            setDealOfTheDayDateValidation(order?.addedDealOFTheDiscount);
            setIsEnabledDiscount(order?.isEnabledDiscount);
            setDiscountObject(order?.discount);
            setPrice(order.price);
            
            setSelectedAddress(order?.address._id);
            setOrderProcess(order?.process);
            const process = order?.process ?? [];
            const filterSteps = steps.filter(step => !process.some(el => el.status === step));
            setOrderProcess([...process, ...filterSteps]);

            const selectedCustomer = customers.find(el => el._id === order?.customer?._id);
            if(selectedCustomer) {
                setCustomer({ value: selectedCustomer._id, label: selectedCustomer.name });
            }
        }
    }, [orderId, customers]);

    useEffect(() => {
        const productItem = products.find(el => el._id === product.value);
        setSelectedProduct(productItem);
        if (productItem) {
            setItems(productItem.items);
            setSizes([...new Set(productItem?.items.map(el => el?.size))]);
            if(action === "create") {
                if(productItem?.isEnabledDealOfTheDay) {
                    const countdownDate = new Date(productItem?.dealOfTheDate).getTime();
                    const now = new Date().getTime();
                    setDealOfTheDayDateValidation(countdownDate >= now);
                  } else {
                    setDealOfTheDayDateValidation(false);
                  }
                  setPrice(productItem.price);
                  setSize("");
                  setQuantity(0);
                  setDiscount(0);
                  setAmount(0);
                  setColor("");
                  setIsEnabledDiscount(productItem?.isEnabledDiscount);
                  setDiscountObject(productItem?.discount);
            }
        }
    }, [product]);

    useEffect(() => {
        const filterItems = items.filter(el => el.size === size);
        console.log(filterItems);
        setColors(filterItems.map(el => el?.colors?.value));
        console.log(filterItems.map(el => el?.colors?.value));
    }, [size]);

    useEffect(() => {
        const order = orders.find(el => el._id === orderId);
        let reQuantity = +selectedProduct?.items.find(el => el.size === size && el?.colors?.value === color)?.quantity || 0;
        console.log(selectedProduct?.items.find(el => el.size === size && el?.colors?.value === color)?.quantity);
        setRemainingQuantity(reQuantity + (orderId ? order.quantity : 0));
        // setColors(((selectedProduct?.items.find(el => el.size === size)?.colors) || [])?.map(el => el.value) || []);
    }, [size, color]);

    useEffect(() => {
        const selectedCustomer = customers.find(el => el._id === customer.value);
        console.log(selectedCustomer);
        if (selectedCustomer) {
            if(!orderId) {
                setReceiversName(selectedCustomer.name);
                setReceiversTelephone(selectedCustomer.telephone);
            }
            if(selectedCustomer?.address.find(el => el?._id === selectedOrder.address?._id)) {
                setCustomerAddress(selectedCustomer?.address);
            } else {
                if(!orderId) {
                    setCustomerAddress([...selectedCustomer?.address]);
                } else {
                    setCustomerAddress([...selectedCustomer?.address, selectedOrder.address]);
                }
            }
        }
    }, [customer]);

    useEffect(() => {
        if(action === "create") {
            if(isCalculateInService && selectedProduct) {
                setPrice(selectedProduct.price);
            }
    
            if (price > 0 && quantity > 0 && selectedProduct && isCalculateInService) {
                console.log(dealOfTheDayDateValidation)
                console.log(selectedProduct)
                console.log(quantity)
                const calculation = productCalculation(dealOfTheDayDateValidation, selectedProduct, quantity);
                console.log(calculation)
                setDiscount(calculation.discount);
                setAmount(calculation.amount);
            }
        }
    }, [quantity, isCalculateInService]);

    const handleClose = () => {
        dispatch(ordersActions.modalHandler(false));
    };

    const isValidationFailed = () => {
        if (!customer?.value) {
            warningNotify("Please choose a customer.");
            return true;
        }
        if (!product.value) {
            warningNotify("Please select a product.");
            return true;
        }
        if (!color) {
            warningNotify("Please pick a color.");
            return true;
        }
        if (!quantity) {
            warningNotify("Please enter the quantity.");
            return true;
        }
        if (quantity > remainingQuantity) {
            warningNotify("Please enter the quantity low than remaining quantity.");
            return true;
        }
        if (!size) {
            warningNotify("Please provide a size.");
            return true;
        }
        if (!isCalculateInService && !price) {
            warningNotify("Please specify a price.");
            return true;
        }
        if (!isCalculateInService && !amount) {
            warningNotify("Please input an amount.");
            return true;
        }
        if (!customerAddress.some(address => address._id === selectedAddress)) {
            warningNotify("Please choose an address.");
            return true;
        }
        return false;
    }

    const createOrder = () => {
        if(isValidationFailed()) {
            return;
        }
        
        const orderJson = {
            customer: customer.value,
            product: product.value,
            color,
            quantity,
            size,
            isCalculateInService,
            selectedAddress,
            amount,
            price,
            receiversName,
            receiversTelephone
        }
        dispatch(ordersService.createOrder(orderJson));
    }

    const updateOrder = () => {
        if(isValidationFailed()) {
            return;
        }

        const orderJson = {
            color,
            size,
            selectedAddress,
            receiversName,
            receiversTelephone
        }
        dispatch(ordersService.updateOrder(orderJson, orderId));
    }

    const quantityHandler = (value) => {
        if (!product) {
            return alert("Please select a product");
        }
        setQuantity(value);
    }

    const priceCal = () => {
        if (price > 0 && quantity > 0 && selectedProduct) {
            const calculation = productCalculation(dealOfTheDayDateValidation, selectedProduct, quantity);
            setDiscount(calculation.discount);
            setAmount(calculation.amount);
        }
    };

    const checkHandler = (value) => {
        setIsCalculateInService(value);
        if (value) {
            priceCal();
        };
    }

    console.log(customerAddress);

    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={modalOpen}
                maxWidth="lg"
                fullWidth={true}
            >
                <DialogTitle sx={{ m: 0, p: 2, backgroundColor: "#1976d2", color: "white" }} id="customized-dialog-title">
                    {title}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: "white",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <div className='row'>
                        <div className="col-4">
                            <label for="exampleFormControlInput1" className={style.input_label}>Customer Name</label>
                            <Select
                                options={modifiedCustomersArray}
                                isSearchable={true}
                                placeholder="Select an option"
                                value={customer}
                                onChange={(customer) => setCustomer(customer)}
                                isDisabled={action === 'view' || action === 'edit'}
                            />
                        </div>
                        <div className="col-4">
                            <label for="exampleFormControlInput1" className={style.input_label}>Product</label>
                            <Select
                                options={modifiedProductsArray}
                                isSearchable={true}
                                placeholder="Select an option"
                                value={product}
                                onChange={(product) => setProduct(product)}
                                isDisabled={action === 'view' || action === 'edit'}
                            />
                        </div>
                        <div className="col-2">
                            <label for="exampleFormControlInput1" className={style.input_label}>Price</label>
                            <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Price" value={price} onChange={(e) => setPrice(e.target.value > 0 ? e.target.value : 1)} disabled={isCalculateInService} />
                        </div>
                        <div className="col-2">
                            <label for="exampleFormControlInput1" className={style.input_label}>Amount</label>{discount > 0 && <span style={{fontSize: 10, marginLeft: 10, color: "red"}}>Discount - {discount}</span>}
                            <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Price" value={amount} onChange={(e) => setAmount(e.target.value > 0 ? e.target.value : 1)} disabled={isCalculateInService} />
                        </div>
                        {isCalculateInService && <div className="row" style={{ position: "relative" }}>
                            {isEnabledDiscount && !dealOfTheDayDateValidation && <div style={{ position: "absolute", color: "red", fontSize: 12, marginTop: 5, textAlign: "right" }}>
                                {discountObject?.item && discountObject?.type === 'percentage' ? `* Price calculate for used ${discountObject?.value}% off for more than ${discountObject?.item} - discount (${symbol}${discount})` :
                                    discountObject?.item && discountObject?.type === 'amount' ? `* Price calculate for used ${discountObject?.value} off for more than ${discountObject?.item} - discount (${symbol}${discount})` : ''}
                            </div>}
                            {dealOfTheDayDateValidation && <div style={{ position: "absolute", color: "red", fontSize: 12, marginTop: 5, textAlign: "right" }}>
                                * Price calculate for used deal of the day price - discount({symbol}{discount})
                            </div>}
                        </div>}
                    </div>
                    <div className='row mt-4'>
                        <div className='col-12'>
                            <div className="row">
                                <div className="col-4">
                                    <label for="exampleFormControlInput1" className={style.input_label}>Size</label>
                                    <select className="form-control" id="exampleFormControlSelect1" placeholder='Select Product' value={size} onChange={(e) => setSize(e.target.value)} disabled={action === 'view'}>
                                        <option>Select size</option>
                                        {[...sizes, ...((sizes.includes(selectedOrder?.size) || !selectedOrder?.size) ? [] : [selectedOrder?.size])].map(size => {
                                            return <option value={size}>{size}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="col-4">
                                    <label for="exampleFormControlInput1" className={style.input_label}>Color</label>
                                    <select className="form-control" id="exampleFormControlSelect1" placeholder='Select Color' value={color} onChange={(e) => setColor(e.target.value)} disabled={action === 'view'}>
                                        <option>Select color</option>
                                        {[...colors, ...((colors.includes(selectedOrder?.color) || !selectedOrder?.color) ? [] : [selectedOrder?.color])].map(color => {
                                            return <option value={color}>{color}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="col-4">
                                    <label for="exampleFormControlInput1" className={style.input_label}>Quantity <span style={{fontSize: 12, color: remainingQuantity < 10 ? "red" : "green"}}>(Remaining - {remainingQuantity})</span></label>
                                    <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Quantity" value={quantity} onChange={(e) => quantityHandler(e.target.value > 0 ? e.target.value : 1)} disabled={action === 'view' || action === 'edit'} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-12'>
                            <input class="form-check-input" type="checkbox" checked={isCalculateInService} onChange={(e) => checkHandler(e.target.checked)} id="flexCheckDefault" disabled={action === 'view' || action === 'edit'} />
                            <label class="form-check-label" for="flexCheckDefault" style={{ marginLeft: 10 }}>
                                If you need to enter a custom price or amount, uncheck here.
                            </label>
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-12'>
                            <div className="row">
                                <div className="col-4">
                                    <label for="exampleFormControlInput1" className={style.input_label}>Receiver's Name</label>
                                    <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Receiver's Name" value={receiversName} onChange={(e) => setReceiversName(e.target.value)} disabled={action === 'view'} />
                                </div>
                                <div className="col-4">
                                    <label for="exampleFormControlInput1" className={style.input_label}>Receiver's Telephone</label>
                                    <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Receiver's Telephone" value={receiversTelephone} onChange={(e) => setReceiversTelephone(e.target.value)} disabled={action === 'view'} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {customerAddress && customerAddress.length > 0 && <div className="row mt-2">
                        <label for="exampleFormControlInput1" className={style.input_label}>Receiver's Address</label>
                        <div className="mt-2 d-flex flex-wrap">
                            {customerAddress.map(address =>
                                <div
                                    className={style.customer_address}
                                    style={selectedAddress === address?._id ? { backgroundColor: "gray", color: "white" } : {}}
                                    onClick={() => action !== 'view' && setSelectedAddress(address._id)}
                                >
                                    <div>{address?.street}</div>
                                    <div>{address?.city}</div>
                                    <div>{address?.state}</div>
                                    <div>{address?.postalCode}</div>
                                    <div>{address?.country}</div>
                                </div>
                            )}
                        </div>
                    </div>}
                    {orderId && <div className="row mt-2">
                        <label for="exampleFormControlInput1" className={style.input_label}>Deliver Information</label>
                        <div className='d-flex'>
                            {orderProcess?.map((step, index) =>
                                <div
                                    key={step._id}
                                    className={style.step}
                                    style={
                                        { backgroundColor: step.date ? stepColors[index] : 'gray', marginRight: 5 }
                                    }
                                >
                                    {step.date ? <div>
                                        <div className={style.step_text}>{step.status}</div>
                                        <div className={style.step_text_date}>{step.date}</div>
                                    </div> : <div className={style.step_text}>{step}</div>}
                                </div>
                            )}
                        </div>
                    </div>}
                </DialogContent>
                {action !== 'view' && <DialogActions>
                    <button type="button" class="btn btn-secondary" onClick={handleClose}>Close</button>
                    {action === "create" && <button className="btn btn-primary" onClick={createOrder}>
                        Create
                    </button>}
                    {action === "edit" && <button className="btn btn-primary" onClick={updateOrder}>
                        Update
                    </button>}
                </DialogActions>}
            </BootstrapDialog>
        </>
    );
}
