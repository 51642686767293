import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addloan: [],
};

const addloanSlice = createSlice({
    name: "event",
    initialState: initialState,
    reducers: {
        getAddloan: (state, action) => {
            const addloan = action.payload;
            console.log(addloan)
            state.addloan = addloan;
        },
        createAddloan: (state, action) => {

        },
    },
});

export const addloanActions = addloanSlice.actions;

export default addloanSlice;