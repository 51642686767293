import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import * as customerService from '../../services/customerService';
import AddOrEditCustomer from './addOrEditCustomer/addOrEditCustomer';
import style from './customer.module.css';
import TableEmpty from '../shared/table/tableEmpty';
import TableSkelton from '../shared/table/tabelSkelton';
import TableHeadSection from '../shared/table/tableHead';
import moment from 'moment';
import TableCellAction from '../shared/table/tabelCellAction';
import TablePaginationSection from '../shared/table/tablePagination';
import DeleteConfirmationModal from '../shared/deleteConfirmationModal';
import { customerActions } from '../../store/customerSlice';
import TableBodyRow from '../shared/table/tableBodyRow';
import { errorNotify } from '../../config/notify';
import PrimaryButton from '../shared/addButton';

const Customer = () => {
    const dispatch = useDispatch();
    const customers = useSelector((state) => state.customer.customerList);
    const isRefreshItems = useSelector((state) => state.customer.isRefreshItems);
    console.log(customers);

    const count = useSelector((state) => state.customer.count);
    const isLoading = useSelector((state) => state.customer.isLoading);
    const isOpenModal = useSelector((state) => state.customer.isOpenModal);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const tableTitles = ["Name", "Email", "Telephone", "Status", "Created At", "updated At", "Action"];

    // console.log(c)
  
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalAction, setModalAction] = useState("");
    const [customerId, setCustomerId] = useState(undefined);

    useEffect(() => {
      getCustomers();
    }, [page, rowsPerPage]);

    useEffect(() => {
      if(isRefreshItems) {
        getCustomers();
      }
    }, [isRefreshItems]);

    const getCustomers = () => {
      const params = {
        page,
        pageSize: rowsPerPage
      };
      dispatch(customerActions.loadingHandler(true));
      dispatch(customerService.getCustomerList(params));
    }
  
    const deleteHandler = (id) => {
      return errorNotify("Features are locked in the demo version.");
      setIsDeleteModalOpen(true);
      setCustomerId(id);
    }
  
    const createCustomer = () => {
      dispatch(customerActions.modalHandler(true));
      setModalTitle("Create Customer");
      setModalAction("create");
      setCustomerId(undefined);
    }
  
    const editCustomer = (p) => {
      return errorNotify("Features are locked in the demo version.");
      dispatch(customerActions.modalHandler(true));
      setModalTitle("Update Customer");
      setModalAction("edit");
      setCustomerId(p._id);
    }
  
    const viewCustomer = (p) => {
      dispatch(customerActions.modalHandler(true));
      setModalTitle("Customer Details");
      setModalAction("view");
      setCustomerId(p._id);
    }

    const deleteModalHandler = (modalAction, reason) => {
      console.log(modalAction);
      if (modalAction === "confirm") {
        dispatch(customerService.deleteCustomer(customerId, reason));
      }
      setIsDeleteModalOpen(false);
      setCustomerId("");
    }
  
    return (
      <div className={style.container}>
        <PrimaryButton name="Create Customer" module="Customer" onClick={createCustomer} />

        <div class="alert alert-primary my-2" role="alert">
          Here, you can manage your customers.
        </div>

      {isOpenModal &&
        <div className={style.product_form}>
          <AddOrEditCustomer
            title={modalTitle}
            modalOpen={isOpenModal}
            action={modalAction}
            customerId={customerId} />
        </div>
      }

      {isDeleteModalOpen && <DeleteConfirmationModal isDeleteModalOpen={isDeleteModalOpen} deleteModalHandler={deleteModalHandler} />}
  
        <div className={style.table}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 350 }} aria-label="simple table">
            <TableHeadSection titles={tableTitles} />

              {!isLoading && customers?.length > 0 && <TableBody>
                {customers.map((customer) => (
                  <TableBodyRow>
                    <TableCell component="th" scope="row">{customer?.name}</TableCell>
                    <TableCell component="th" scope="row">{customer?.email}</TableCell>
                    <TableCell component="th" scope="row">{customer?.telephone}</TableCell>
                    <TableCell component="th" scope="row">{customer?.telephone}</TableCell>
                    <TableCell component="th" scope="row">{moment(customer?.createdAt).format("yyyy-MM-DD HH:MM a")}</TableCell>
                    <TableCell component="th" scope="row">{moment(customer?.updatedAt).format("yyyy-MM-DD HH:MM a")}</TableCell>

                    <TableCellAction module="Customer" viewItem={() => viewCustomer(customer)} editItem={() => editCustomer(customer)} deleteItem={() => deleteHandler(customer._id)} />
                  </TableBodyRow>
                ))}
              </TableBody>}

              {!isLoading && !customers?.length && <TableEmpty />}

              {isLoading && <TableSkelton row={4} column={7} />}

            </Table>
          </TableContainer>

          <TablePaginationSection count={count} page={page} rowsPerPage={rowsPerPage} setPage={setPage} setRowsPerPage={setRowsPerPage} />
        </div>
      </div >
    );
};

export default Customer;
