import axios from "axios"
import { addloanActions } from "../store/addloanSlice"
import { API_HOST } from "../config/config"

export const getAddloan = () => (dispatch) => {
    axios({
        method: 'get',
        url: API_HOST + '/loans',
    }).then(res => {
        console.log(res.data.data)
        dispatch(addloanActions.getAddloan(res.data.data))
        // console.log(res);
    })
}

export const adloan = (name, amount, dateofIssue) => (dispatch) => {
    axios({
        method: 'post',
        url: API_HOST + '/loan',
        data: {
            name: name,
            amount: amount,
            date: dateofIssue
        }
    }).then(res => {
        dispatch(getAddloan());
    });
};

export const deleteloan = (loan) => (dispatch) => {
    axios({
        method: 'delete',
        url: API_HOST + `/loan/${loan._id}`,

    }).then(res => {
        dispatch(getAddloan());
    });
};

export const editloan = (name, amount, dateofIssue, id) => (dispatch) => {
    axios({
        method: 'put',
        url: API_HOST + `/loan/${id}`,
        data: {
            name: name,
            amount: amount,
            date: dateofIssue
        }
    }).then(res => {
        dispatch(getAddloan());
    });
}