import { useSelector } from "react-redux";

const PrimaryButton = ({ name, module, onClick }) => {
    const systemAccess = useSelector((state) => state.auth.access);

    return (
        <>
            {<button className="btn btn-primary" style={{ width: 150 }} onClick={onClick}>
            {/* {systemAccess.some(access => access.module === module && access.create) && <button className="btn btn-primary" style={{ width: 150 }} onClick={onClick}> */}
                {name}
            </button>}
        </>
    );
}

export default PrimaryButton;