import { configureStore } from "@reduxjs/toolkit";
import categorySlice from "./categorySlice";
import stockSlice from "./stockSlice";
import addloanSlice from "./addloanSlice";
import authSlice from "./authSlice";
import loanSlice from "./loanSlice";
import productSlice from "./productSlice";
import ordersSlice from "./ordersSlice";
import staffSlice from "./staffSlice";
import customerSlice from "./customerSlice";
import settingsSlice from "./settingsSlice";
import salesShopSlice from "./salesShop";
import systemRolesSlice from "./systemRoles";


const store = configureStore({
    reducer: {
        category: categorySlice.reducer,
        stock: stockSlice.reducer,
        addloan: addloanSlice.reducer,
        loan: loanSlice.reducer,
        auth: authSlice.reducer,
        product: productSlice.reducer,
        orders: ordersSlice.reducer,
        staff: staffSlice.reducer,
        customer: customerSlice.reducer,
        settings: settingsSlice.reducer,
        salesShop: salesShopSlice.reducer,
        systemRoles: systemRolesSlice.reducer,
    }
});

export default store;