import { categoryActions } from "../store/categorySlice";
import { API_HOST } from '../config/config';
import { errorNotify } from '../config/notify';
import axiosInstance from '../config/axiosInstance';

export const getCounts = (params) => {
    return axiosInstance({
        method: 'get',
        url: API_HOST + '/dashboard/counts',
        params
    }).then(res => {
        return res.data;
        // dispatch(categoryActions.loadingHandler(false));
        // dispatch(categoryActions.getCategories(res.data.data));
    }).catch(err => {
        console.log(err.response);
        errorNotify(err.response?.data?.message || err.message);
        // dispatch(categoryActions.loadingHandler(false));
    });
};