import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    stocks: [],
    isLoading: false,
    isOpenModal: false,
    count: 0,
    isRefreshItems: false
};

const stockSlice = createSlice({
    name: "event",
    initialState: initialState,
    reducers: {
        getStocks: (state, action) => {
            const stocks = action.payload;
            state.stocks = stocks.stocks ?? [];
            state.count = stocks.count;
            state.isRefreshItems = false;
        },
        loadingHandler: (state, action) => {
            state.isLoading = action.payload;
        },
        modalHandler: (state, action) => {
            state.isOpenModal = action.payload;
        },
        refreshItems: (state, action) => {
            state.isRefreshItems = true;
        },
    },
});

export const stockActions = stockSlice.actions;

export default stockSlice;